// Colors
$SurfBlue100: #177abf;
$SurfBlue75: #519bcf;
$SurfBlue50: #8bbcdf;
$SurfBlue25: #d0e4f2;

$SurfRed100: #e7303a;
$SurfRed75: #ed636b;
$SurfRed50: #f2979c;
$SurfRed25: #fad5d7;

$SurfOrange100: #ee7628;
$SurfOrange75: #f2975d;
$SurfOrange50: #f6ba93;
$SurfOrange25: #fbe3d4;

$SurfGreen100: #2ca055;
$SurfGreen75: #60b77f;
$SurfGreen50: #95cfaa;
$SurfGreen25: #d4ecdd;

$SurfPurple100: #7b2882;
$SurfPurple75: #9c5da1;
$SurfPurple50: #bc93c0;
$SurfPurple25: #e4d4e6;

$SurfLime100: #a5c251;
$SurfLime75: #bbd17b;
$SurfLime50: #d1e0a7;
$SurfLime25: #edf2dc;

$Black: #414042;
$BlackMedium: #6d6e71;
$BlackLight: #acb3b9;
$Grey: #d9dcde;
$GreyMedium: #e6e9eb;
$GreyLight: #f2f4f5;
$White: #ffffff;
$WhiteCream: #f5f6fa;

$StatusRed: #e0454f;
$StatusRedLight: #fbeced;
$StatusOrange: #f39848;
$StatusOrangeLight: #ffeedf;
$StatusYellow: #efce5e;
$StatusYellowLight: #faf2d6;
$StatusGreen: #3cb66f;
$StatusDarkGreen: #27844e;
$StatusGreenLight: #ceecda;
$StatusNeutral: $GreyMedium;
$StatusNeutralLight: #f8f9fa;

// Breakpoints
$breakpoint-xs: 690px;
$breakpoint-s-1: 691px;
$breakpoint-s-2: 840px;
$breakpoint-m-1: 841px;
$breakpoint-m-2: 1100px;
$breakpoint-l-1: 1101px;
$breakpoint-l-2: 1599px;
$breakpoint-xl: 1600px;

// Fonts
$font-heading:
  "Proxima",
  -apple-system,
  BlinkMacSystemFont,
  segoe ui,
  roboto,
  oxygen,
  ubuntu,
  cantarell,
  fira sans,
  droid sans,
  helvetica neue,
  sans-serif;
$font-normal:
  "Source Sans Pro",
  -apple-system,
  BlinkMacSystemFont,
  segoe ui,
  roboto,
  oxygen,
  ubuntu,
  cantarell,
  fira sans,
  droid sans,
  helvetica neue,
  sans-serif;

// Sizes
$topbar-height: 92px;
$navigation-height: 112px;

// Transitions
$transition-fast: 0.235s ease;

// Border-radius
$border-radius-s: 2px;
$border-radius: 4px;
$border-radius-l: 8px;

// Box-shadow
$box-shadow:
  0 1px 2px rgba(black, 0.06),
  0 1px 3px rgba(black, 0.1);
$box-shadow-light: 0 1px 2px rgba(0, 0, 0, 0.06);

// Border-color
$border-color: $GreyMedium;

// Mixins

@mixin border() {
  border: 1px solid $border-color;
}

@mixin border-top() {
  border-top: 1px solid $border-color;
}

@mixin border-bottom() {
  border-bottom: 1px solid $border-color;
}
