@use "variables.scss";

.placeholder {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgba(white, 0),
      rgba(white, 0) 25%,
      rgba(white, 1) 50%,
      rgba(white, 0) 75%,
      rgba(white, 0)
    );
    animation: 3s placeholderLoad infinite;
    z-index: 1;
  }
}
@keyframes placeholderLoad {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
.placeholder__top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}
.placeholder__top-left {
  display: flex;
}
.placeholder__status {
  width: 24px;
  height: 24px;
  border-radius: 99em;
  background: variables.$GreyMedium;
  flex-shrink: 0;
  margin-right: 8px;
}

.placeholder__title {
  width: 136px;
}
.placeholder__caption {
  padding-top: 8px;
}
.placeholder__stroke {
  border-radius: 99em;
  height: 8px;
  background: variables.$GreyMedium;
  max-width: 180px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}
.placeholder__tag {
  width: 48px;
  height: 24px;
  background: variables.$GreyMedium;
  border-radius: 4px;
}
.placeholder__dots {
  display: flex;
  flex-direction: column;

  span {
    width: 4px;
    height: 4px;
    border-radius: 99em;
    margin-top: 4px;
    background: variables.$GreyMedium;
  }
}

.placeholder__table {
  height: 88px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 24px;

  span {
    width: 100%;
    height: 1px;
    background: variables.$GreyMedium;
  }
}
.placeholder__ports {
  display: flex;
  width: 80%;
}
.placeholder__bottom {
  display: flex;
  align-items: center;
}
.placeholder__port {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  .placeholder__stroke:nth-child(1) {
    width: 30%;
  }
  .placeholder__stroke:nth-child(2) {
    width: 70%;
  }
}

.placeholder__in-out {
  border-radius: 4px;
  border: 1px solid variables.$GreyMedium;
  height: 56px;
  width: 20%;
}

.placeholder__pie-aspect {
  margin: 0 auto 40px auto;
  width: 100%;
  max-width: 224px;
  position: relative;
  &:before {
    content: "";
    display: block;
    padding-top: 100%;
  }
}

.placeholder__pie {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 99em;
  border: 24px solid variables.$GreyMedium;
}

.placeholder__route {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}
.placeholder__route-col {
  width: 28px;
  height: 28px;
  position: relative;

  &:nth-child(1) {
    border-top: 1px solid variables.$GreyMedium;
    border-bottom: 1px solid variables.$GreyMedium;

    &:before {
      content: "";
      position: absolute;
      right: 100%;
      bottom: -5px;
      border: 5px solid transparent;
      border-right-color: variables.$GreyMedium;
    }
  }

  &:nth-child(2) {
    &:before,
    &:after {
      content: "";
      position: absolute;
      width: 1px;
      height: 100%;
      background-color: variables.$GreyMedium;
      top: 0;
    }
    &:before {
      left: 0;
      transform: skew(45deg);
      transform-origin: top left;
    }
    &:after {
      right: 0;
      transform: skew(-45deg);
      transform-origin: top right;
    }
  }
  &:nth-child(3) {
    border-top: 1px solid variables.$GreyMedium;
    border-bottom: 1px solid variables.$GreyMedium;

    &:after {
      content: "";
      position: absolute;
      left: 100%;
      bottom: -5px;
      border: 5px solid transparent;
      border-left-color: variables.$GreyMedium;
    }
  }
}
