@use "_type.scss";
@use "variables.scss";

.service {
  &--clickable {
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      box-shadow:
        0 4px 6px -1px rgba(0, 0, 0, 0.15),
        0 2px 4px -1px rgba(0, 0, 0, 0.1);
    }
  }

  &:focus {
    outline: none;
  }
}
.service__header {
  padding: 24px;

  @media (min-width: variables.$breakpoint-xs) {
    display: flex;
  }
}
.service-icon-wireless {
  color: variables.$SurfPurple100;
  width: 24px;
  height: 24px;

  svg {
    width: 24px;
    height: 24px;
  }
}
.service__left {
  display: flex;
  flex-grow: 1;
  min-width: 0;

  &--no-padding {
    padding: 0 !important;
  }

  @media (min-width: variables.$breakpoint-xs) {
    padding-right: 16px;
  }

  .tags {
    @media (min-width: variables.$breakpoint-xs) {
      margin-bottom: 0;
    }
  }
}
.service__right {
  padding-left: 40px;

  @media (min-width: variables.$breakpoint-xs) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    padding-left: 0;
  }
}
.service__header-caption {
  flex-grow: 1;
  padding-left: 20px;
  min-width: 0;
}
.service__title-wrapper {
  display: flex;
  align-items: center;

  &--space-between {
    justify-content: space-between;
  }

  + .tags {
    margin-top: 0;
  }
}
.service__title {
  display: flex;
  margin-bottom: 0;
  font-weight: 600;
}
.service__title-arrow {
  opacity: 0;
  transition: 0.2s ease;
  transform: translateX(-4px);

  .card--clickable:hover & {
    opacity: 1;
    transform: translateX(0);
  }
}
.service__subtitle {
  margin-bottom: 24px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  @include type.type-300;
}
.service__endpoint:not(:last-child) > span:after {
  content: " - ";
}
.service__edit-icon {
  color: variables.$BlackLight;
  cursor: pointer;
  margin-left: 4px;
  top: -2px;
  position: relative;

  &:hover {
    color: variables.$Black;
  }
}
.service__button {
  display: inline-flex;
  align-items: center;
  border-radius: variables.$border-radius;
  border: 1px solid variables.$GreyMedium;
  height: 32px;
  padding: 0 12px;
  font-family: variables.$font-heading;
  @include type.font-size(12px);
  text-transform: uppercase;
  cursor: pointer;
  color: variables.$Black;
  transition: all 0.2s ease;
  margin-bottom: 24px;
  white-space: nowrap;
  font-weight: 600;

  &:hover {
    border-color: variables.$Grey;
  }
}
.service__current {
  display: flex;
}
.service__current-col {
  &:not(:last-child) {
    padding-right: 16px;
  }
}
.service__current-title {
  font-family: variables.$font-heading;
  color: variables.$Black;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 4px;
  @include type.font-size(12px);
}
.service__status-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid variables.$GreyMedium;
  padding: 16px 0;
}
.service-status__icon {
  height: 20px;
  width: 20px;
  margin-right: 8px;
  svg {
    width: 20px;
    height: 20px;
    fill: currentColor;
  }
}
.service {
  transition: 0.3s all ease;
  &--red {
    color: variables.$SurfRed100;
  }
  &--blue {
    color: variables.$SurfBlue100;
  }
}
.service-status__title {
  font-weight: bold;
}

.service__status {
  hr {
    margin-top: 24px;
  }
}

.alias__edit-form {
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin-bottom: 16px;
}
.alias__edit-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  flex-shrink: 0;

  svg-icon {
    display: flex;
  }

  &--save {
    color: variables.$SurfBlue100;
    margin-left: 4px;
  }

  &--cancel {
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }
}

.service__graph {
  padding: 0 24px 24px 24px;
  margin-top: -12px;
}

.service__flex-table {
  display: flex;
  flex-direction: column;
  padding: 4px 0;

  .service__left + & {
    margin-top: 20px;
  }
}

.service__flex-table-row {
  display: flex;
  // border-bottom: 1px solid $GreyMedium;
  // padding: 10px 0;
  flex-wrap: wrap;

  // &:first-child {
  //   border-top: 1px solid $GreyMedium;
  // }
}
.service__flex-table-col {
  width: 100%;
  padding: 4px 0;

  @media (min-width: variables.$breakpoint-xs) {
    display: flex;
  }
}
.service__flex-table-title {
  color: variables.$Black;
  font-weight: bold;

  @media (min-width: variables.$breakpoint-xs) {
    width: 25%;
    flex-shrink: 0;
  }
}

.service__flex-table-subtitle {
  padding-right: 16px;
}

.service__unavailable-bar {
  background-color: variables.$WhiteCream;
  border: 1px solid variables.$GreyMedium;
  height: 48px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
}

.service__unavailable-bar-side {
  width: 48px;
  padding: 0 12px;

  svg-icon {
    display: flex;
  }

  svg-icon path {
    fill: variables.$BlackMedium;
  }
}

.service__unavailable-bar-text {
  white-space: nowrap;
  text-overflow: ellipsis;

  overflow: hidden;
}

.service__access-points {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 24px;

  @media (max-width: variables.$breakpoint-xs) {
    margin-bottom: 32px;
  }
}

.service__access-points-item {
  display: flex;
  align-items: center;
  gap: 8px;

  span:first-child {
    font-family: variables.$font-heading;
    color: variables.$Black;
    font-weight: 600;
    font-size: 12px;
  }

  &:before {
    content: "";
    flex-shrink: 0;
    width: 8px;
    height: 8px;
    border-radius: 99em;
    background-color: #ddd;
  }

  &:nth-child(1):before {
    background-color: variables.$StatusGreen;
  }
  &:nth-child(2):before {
    background-color: variables.$SurfBlue50;
  }
}

.list-alias-editor {
  .alias {
    font-family: Proxima;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: variables.$Black;
  }

  .alias__form {
    margin-bottom: 16px;
  }
}
