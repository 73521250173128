@use "variables.scss";

.connection {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .overflow-menu {
      margin-bottom: 16px;
    }
  }
  port-tile {
    display: flex;
    flex: 1;
    overflow: hidden;
  }
  port-tile {
    display: flex;
    flex: 1;
    overflow: hidden;
  }
  &__row {
    margin-bottom: 40px;
  }
  &__title {
    h3 span {
      font-weight: 300;
      margin-left: 8px;
    }
  }

  &__flex {
    display: flex;

    @media (max-width: variables.$breakpoint-m-2) {
      display: block;
    }
  }

  .card {
    flex-grow: 1;
  }

  &__connector-meta {
    text-align: center;
    font-size: 14px;

    @media (max-width: variables.$breakpoint-m-2) {
      margin: 8px 16px 0 16px;
    }
  }

  &__connector-heading {
    font-family: variables.$font-heading;
    color: variables.$Black;
    text-transform: uppercase;
    font-size: 12px;
    margin-bottom: 2px;
  }

  &__connector-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;

    @media (max-width: variables.$breakpoint-m-2) {
      flex-direction: row;
      justify-content: center;
    }
  }

  &__connector {
    margin: 16px 0;
    display: flex;
    width: 144px;
    height: 74px;

    @media (max-width: variables.$breakpoint-m-2) {
      display: none;
    }

    > div {
      position: relative;
    }

    span {
      background-color: variables.$Grey;
      position: relative;
      overflow: hidden;
    }
  }
  &__connector-left,
  &__connector-right {
    width: 30%;
    height: 100%;

    > span {
      position: absolute;
      left: 0;
      width: 100%;
      height: 2px;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: -20px;
        width: 20px;
        height: 100%;
        background: linear-gradient(90deg, variables.$Grey, variables.$BlackMedium, variables.$Grey);
        animation: 2.2s connectionAnimationX linear alternate-reverse infinite;
      }
    }
  }

  &__connector-left-top,
  &__connector-right-top {
    top: 0;
  }

  &__connector-left-bottom,
  &__connector-right-bottom {
    bottom: 0;
  }

  &__connector-center {
    width: 40%;
    height: 100%;

    > span {
      position: absolute;
      left: calc(50% - 1px);
      width: 2px;
      height: 100%;

      &:before {
        content: "";
        position: absolute;
        top: -20px;
        left: 0;
        height: 20px;
        width: 100%;
        background: linear-gradient(0deg, variables.$Grey, variables.$BlackMedium, variables.$Grey);
        animation: 2.2s connectionAnimationY linear alternate-reverse infinite;
      }
    }
  }

  &__connector-center-left {
    transform: skew(38deg);

    &:before {
      animation-delay: 0.8s !important;
    }
  }
  &__connector-center-right {
    transform: skew(-38deg) rotate(180deg);

    &:before {
      animation-delay: 0.8s !important;
    }
  }

  &__connector-right-top,
  &__connector-right-bottom {
    &:before {
      animation-delay: 1.8s !important;
    }
  }
}

@keyframes connectionAnimationX {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(80px);
  }
}
@keyframes connectionAnimationY {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(110px);
  }
}
