@use "variables.scss";

body {
  background-color: variables.$WhiteCream;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: variables.$font-normal;
  font-size: 14px;
  color: variables.$BlackMedium;
}

.hide-everything-after-this-div {
  ~ * {
    display: none;
  }
}

svg-icon {
  display: flex;
}

.section {
  margin-bottom: 64px;
  width: 100%;

  .filter-bar {
    margin-bottom: 4px;
  }

  @media (max-width: variables.$breakpoint-xs) {
    margin-bottom: 0;
  }
}
.col--flex {
  display: flex;
  flex-direction: column;
}

.wrapper {
  min-height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.section-top {
  border-bottom: 1px solid variables.$GreyMedium;
  padding-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 56px;

  @media (max-width: variables.$breakpoint-m-1) {
    display: none;
  }
}
.notification-email {
  line-height: 1.4;
  margin: 0;
}
.cut {
  max-width: 280px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.title-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  @media (max-width: variables.$breakpoint-xs) {
    .button {
      display: none;
    }
  }

  h2,
  h3 {
    margin: 0;
  }
}

.no-margin {
  margin: 0;
}
.login-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: variables.$breakpoint-xs) {
    flex-direction: column;
  }
}
.black {
  color: variables.$Black !important;
}
.blue {
  color: variables.$SurfBlue100 !important;
}
.green {
  color: variables.$StatusGreen;
}
.red {
  color: variables.$StatusRed !important;
}
.orange {
  color: variables.$StatusOrange;
}
.yellow {
  color: variables.$StatusYellow;
}
.purple {
  color: variables.$SurfPurple100;
}
.black-medium {
  color: variables.$BlackMedium;
}
.semi {
  opacity: 0.5;

  animation: Pulsate 4s linear infinite;
}
.i-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: variables.$GreyLight;
  border-radius: 99em;
  background-image: url("/assets/images/icons/info.svg");
  background-size: 10px;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  left: 8px;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.icon {
  width: 128px;
  height: 128px;
  border-radius: 99em;
  border: 1px solid variables.$GreyLight;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;

  svg {
    width: 60px;
    height: 60px;
  }
}

.tip {
  display: flex;
  align-items: center;
}
.tip__icon {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 99em;
  border: 2px solid variables.$GreyMedium;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.tip__text {
  color: variables.$BlackMedium;
  padding-left: 8px;
  font-size: 11px;
  line-height: 16px;

  &--touch {
    @media (max-width: variables.$breakpoint-s-2) {
      display: block;
    }
  }
  &--desktop {
    @media (min-width: variables.$breakpoint-m-1) {
      display: block;
    }
  }
}

.divider {
  display: flex;
  flex-direction: column;
  margin-bottom: 46px;

  span {
    border-top: 2px dotted variables.$Grey;
    margin: 1px 0;
  }
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

@keyframes Pulsate {
  from {
    opacity: 0.7;
  }
  50% {
    opacity: 0.2;
  }
  to {
    opacity: 0.7;
  }
}

.togglebar {
  // for more than 2 buttons
  // perhaps remove all radiuses from buttons
  // and only add radius for first and last?
  button:first-child {
    border-right: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  button:last-child {
    border-left: 0px;
    border-top-left-radius: 0%;
    border-bottom-left-radius: 0%;
  }
}
