@use "variables.scss";

.tooltip__wrapper {
  position: relative;
}

.tooltip {
  background-color: variables.$Black;
  color: white;
  border-radius: variables.$border-radius;
  padding: 8px;
  position: absolute;
  bottom: 100%;
  font-size: 11px;
  line-height: 1.5;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 100%;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid variables.$Black;
  }
}

.mat-tooltip {
  color: white !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 1.5 !important;
  font-family: variables.$font-heading !important;
  background-color: variables.$Black !important;
  box-shadow: 0 1px 2px 0 rgba(black, 0.2);
  border-radius: variables.$border-radius !important;
  padding: 8px !important;
}

.info {
  width: 16px;
  height: 16px;
  line-height: 16px;
  border-radius: 99em;
  background-color: variables.$Grey;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  color: variables.$Black;
  position: relative;
  top: -1px;

  &:hover {
    color: variables.$SurfBlue100;
  }

  &:before {
    content: "";
    width: 2px;
    height: 2px;
    margin-bottom: 1px;
    background-color: currentColor;
    border-radius: 99em;
  }
  &:after {
    content: "";
    width: 2px;
    height: 6px;
    margin-top: 1px;
    background-color: currentColor;
    border-radius: 99em;
  }
}
