@use "_type.scss";
@use "variables.scss";

.chart {
  &__header {
    padding: 24px;
    display: flex;
    justify-content: space-between;

    @media (max-width: variables.$breakpoint-s-2) {
      flex-direction: column;
    }
  }

  &__header-date-inputs {
    display: flex;
    align-items: center;

    @media (max-width: variables.$breakpoint-s-2) {
      flex-direction: column;

      .chart__select-wrapper:not(:first-child) {
        margin-top: 8px;
      }
    }
  }

  &__header-right-flex {
    display: flex;
    justify-content: space-between;
  }

  &__period-filter {
    display: flex;
    gap: 8px;

    button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-color: transparent;
      font-family: variables.$font-heading;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 14px;
      color: variables.$BlackMedium;
      padding: 0;
      transition: all 0.2s ease;

      &:hover {
        color: variables.$SurfBlue100;
      }

      &.active {
        color: variables.$SurfBlue100;
      }
    }
  }

  &__header-date-seperator {
    margin: 0 4px;

    @media (max-width: variables.$breakpoint-xs) {
      display: none;
    }
  }

  &__header-left {
    display: flex;
    flex-wrap: wrap;

    @media (max-width: variables.$breakpoint-xs) {
      margin-bottom: 16px;
    }
  }

  &__header-left-col {
    padding-right: 16px;

    @media (max-width: variables.$breakpoint-xs) {
      padding-right: 0;
      width: 100%;

      + .chart__header-left-col {
        margin-top: 16px;
      }
    }
  }

  &__select-calendar-icon {
    color: variables.$BlackLight;
    transition: all 0.2s ease;

    .active + & {
      color: variables.$SurfBlue100;
    }
  }

  &__select-label {
    display: block;
    @include type.type-300;
    font-family: variables.$font-heading;
    font-weight: 600;
    margin-bottom: 4px;
    color: variables.$Black;

    span {
      display: inline-block;
    }
  }

  &__select-reset {
    color: variables.$SurfBlue100;
    margin-left: 8px;
    cursor: pointer;
  }

  &__select-wrapper {
    display: inline-flex;
    position: relative;

    select,
    input {
      max-width: none;
    }

    @media (max-width: variables.$breakpoint-m-2) {
      max-width: none;
      width: 100%;

      select {
        width: 100%;
        max-width: none;
      }
    }

    svg-icon {
      position: absolute;
      right: 8px;
      top: 12px;
      pointer-events: none;
    }
  }
  &__select,
  &__input {
    width: 100%;
    height: 48px;
    border: 1px solid variables.$GreyMedium;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    padding: 0 40px 0 16px;
    border-radius: variables.$border-radius;
    cursor: pointer;
    box-shadow: 0 1px 2px rgba(black, 0.06);
    font-family: variables.$font-normal;
    color: variables.$Black;
    overflow: hidden;
    max-width: 336px;
    @include type.type-400;
    transition: 0.2s ease;

    @media (max-width: variables.$breakpoint-xs) {
      width: 100%;
      max-width: none;
    }

    &:hover {
      border-color: variables.$Grey;
    }
  }

  &__center {
    position: relative;
    border-top: 1px solid variables.$GreyMedium;
    border-bottom: 1px solid variables.$GreyMedium;
  }

  &__bottom {
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: variables.$breakpoint-m-2) {
      flex-direction: column;
    }
  }

  &__bottom-right {
    display: flex;

    @media (max-width: variables.$breakpoint-xs) {
      width: 100%;
      flex-wrap: wrap;
    }
  }

  &__current-traffic {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    background-color: variables.$White;
    z-index: 2;
    height: 40px;
    border: 1px solid variables.$GreyMedium;
    border-top: 0;
    border-right: 0;
    border-radius: 0 0 0 variables.$border-radius 0;
    // box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    padding: 8px 16px 8px 16px;
    @include type.type-300();
  }

  &__current-traffic-text {
    + .chart__current-traffic-text {
      padding-left: 16px;
    }
    span {
      text-transform: uppercase;
      font-family: variables.$font-heading;
      color: variables.$Black;
      font-weight: 600;
      margin-right: 2px;
    }
  }

  &__legend {
    display: flex;
    flex-wrap: wrap;

    .chart__select-wrapper {
      margin-right: 24px;

      @media (max-width: variables.$breakpoint-xs) {
        margin-right: 0;
        margin-bottom: 16px;
      }
    }

    @media (max-width: variables.$breakpoint-m-2) {
      margin-bottom: 16px;
    }
  }

  &__legend-item-misc {
    display: flex;
    align-items: center;
    color: variables.$Black;
    @include type.type-300;
    margin-right: 24px;
    cursor: pointer;
    padding: 8px 0;

    &.inactive {
      opacity: 0.5;
    }

    .bolletje {
      content: "";
      width: 16px;
      height: 4px;
      border-radius: 99em;
      background-color: variables.$GreyMedium;
      margin-right: 8px;
      flex-shrink: 0;
    }
  }

  &__legend-item {
    display: flex;
    align-items: center;
    color: variables.$Black;
    @include type.type-300;
    margin-right: 24px;
    cursor: pointer;
    padding: 8px 0;

    &.inactive {
      opacity: 0.5;
    }

    &:before {
      content: "";
      width: 16px;
      height: 4px;
      border-radius: 99em;
      background-color: variables.$GreyMedium;
      margin-right: 8px;
      flex-shrink: 0;
    }

    &--1:before {
      background-color: variables.$SurfBlue100;
    }
    &--2:before {
      background-color: variables.$SurfRed100;
    }
    &--3:before {
      background-color: variables.$SurfOrange100;
    }
    &--4:before {
      background-color: variables.$SurfGreen100;
    }

    &--5:before {
      background-color: #8b5cf6;
    }
    &--6:before {
      background-color: #ec4899;
    }
    &--7:before {
      background-color: #34d399;
    }
    &--8:before {
      background-color: #fbbf24;
    }
  }

  &__radio-buttons {
    display: flex;
    padding: 4px 0;
  }

  &__radio-buttons-item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    text-transform: uppercase;
    font-family: variables.$font-heading;
    border: 1px solid variables.$GreyMedium;
    @include type.type-300;
    padding: 0 12px;
    cursor: pointer;
    color: variables.$Black;
    font-weight: 600;

    @media (max-width: variables.$breakpoint-xs) {
      flex: 1;
    }

    &:hover {
      border-color: variables.$Grey;
    }

    &.active {
      background-color: variables.$SurfBlue100;
      border-color: variables.$SurfBlue100;
      color: variables.$White;

      &:hover {
        border-color: variables.$SurfBlue100;
      }
    }

    + .chart__radio-buttons-item {
      border-left: 0;
    }

    &:first-child {
      border-radius: variables.$border-radius 0 0 variables.$border-radius;
    }
    &:last-child {
      border-radius: 0 variables.$border-radius variables.$border-radius 0;
    }
  }

  &__export-button-wrapper {
    position: relative;
    padding: 4px 0;
    margin-left: 12px;
  }

  &__export-button {
    height: 40px;
    width: 40px;
    border: 1px solid variables.$GreyMedium;
    display: flex;
    align-items: center;
    justify-content: center;
    color: variables.$Black;
    border-radius: variables.$border-radius;
    cursor: pointer;

    &:hover {
      border-color: variables.$Grey;
    }
  }

  &__export-overflow {
    position: absolute;
    right: 0;
    bottom: 100%;
    background-color: white;
    border: 1px solid variables.$GreyMedium;
    border-radius: variables.$border-radius;
    box-shadow: variables.$box-shadow;
    animation: 0.3s exportOverflow forwards;
  }

  &__export-overflow-item {
    white-space: nowrap;
    cursor: pointer;
    display: flex;
    padding: 8px 16px 8px 8px;
    color: variables.$Black;

    &:hover {
      background-color: variables.$WhiteCream;
    }

    &:not(:first-child) {
      border-top: 1px solid variables.$GreyMedium;
    }
  }

  &__happy-state {
    position: relative;
    height: 392px;
    background-color: variables.$WhiteCream;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    font-family: variables.$font-heading;
    color: variables.$Black;
    font-size: 16px;
    font-weight: 600;
    text-align: center;

    svg-icon {
      display: block;
      margin: 0 auto;
      color: variables.$GreyMedium;
      margin-bottom: 20px;
    }
  }
}

.highcharts-loading-inner {
  display: block;
  animation: 0.7s chartloader linear infinite;
  background-image: url("/assets/images/icons/loader.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  left: 48%;
  position: absolute;
  width: 24px;
  height: 24px;
}

@keyframes exportOverflow {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes chartloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
