@use "variables.scss";

.radio {
  margin-bottom: 16px;
  input[type="radio"] {
    opacity: 0;
    position: absolute;
    height: 16px;
    width: 16px;

    + label {
      cursor: pointer;
      padding-left: 24px;
      line-height: 16px;
      position: relative;
      display: inline-block;
      margin-bottom: 0px;

      &:hover:after {
        transform: scale(1);
      }
    }

    + label:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 16px;
      height: 16px;
      background-color: white;
      border: 2px solid variables.$BlackLight;
      border-radius: 99em;
      transition: variables.$transition-fast;
    }

    + label:after {
      content: "";
      position: absolute;
      top: 4px;
      left: 4px;
      width: 8px;
      height: 8px;
      background-color: variables.$BlackLight;
      border-radius: 99em;
      transition: variables.$transition-fast;
      transform: scale(0);
    }

    &:checked + label {
      &:before {
        border-color: variables.$SurfBlue100;
      }
      &:after {
        background-color: variables.$SurfBlue100;
        transform: scale(1);
      }
    }
  }
}
.form-label + .radio,
.form-label + .radio__flex {
  margin-top: 16px;
}

.radio__flex {
  display: flex;
  flex-wrap: wrap;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  .radio {
    margin-right: 24px;
  }
}
