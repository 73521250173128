@use "variables.scss";

.toasters {
  margin-bottom: 40px;
}

.toaster {
  background-color: white;
  display: block;
  border-radius: variables.$border-radius-l;
  border: 0;
  border-left: 4px solid variables.$GreyMedium;
  margin-bottom: 16px;
  padding: 0;
  box-shadow: variables.$box-shadow;

  &--blue {
    border-left-color: variables.$SurfBlue100;
  }
  &--orange {
    border-left-color: variables.$SurfOrange100;
  }
  &--red {
    border-left-color: variables.$SurfRed100;
  }

  markdown {
    display: block;
    overflow: hidden;
    @media (max-width: variables.$breakpoint-xs) {
      max-height: 300px;
    }
    & {
      max-height: 500px;
    }
  }
}
.toaster__top {
  display: flex;
  justify-content: space-between;
  padding: 16px 16px 16px 12px;
  cursor: pointer;

  @media (max-width: variables.$breakpoint-xs) {
    padding: 12px;
  }

  &:hover {
    .toaster__link {
      color: variables.$SurfBlue100;
    }
  }

  &.clicked {
    .toaster__arrow {
      transform: rotate(180deg);
    }
    + .toaster__bottom {
      max-height: 1024px;

      @media (max-width: variables.$breakpoint-xs) {
        max-height: 600px;
        overflow-y: scroll;
      }
    }
  }
}
.toaster__top__left {
  display: flex;
  align-items: center;
}
.toaster__title {
  padding-left: 16px;
  margin: 0;

  @media (max-width: variables.$breakpoint-xs) {
    font-size: 13px;
    line-height: 16px;
  }
}
.toaster__icon {
  width: 32px;
  height: 32px;
  border-radius: 99em;
  background-color: #eee;
  flex-shrink: 0;
  display: flex !important;
  align-items: center;
  justify-content: center;

  svg {
    width: 16px;
    height: 16px;
  }

  &--red {
    background-color: variables.$SurfRed25;
    color: variables.$SurfRed100;
  }
  &--orange {
    background-color: variables.$SurfOrange25;
    color: variables.$SurfOrange100;
  }
  &--blue {
    background-color: variables.$SurfBlue25;
    color: variables.$SurfBlue100;
  }
}
.toaster__arrow,
.toaster__arrow svg {
  width: 16px;
  height: 16px;
  transform-origin: center;
}
.toaster__arrow {
  transform-origin: center center 100px;
  transition: transform variables.$transition-fast;
}
.toaster__link {
  display: flex;
  align-items: center;
  color: variables.$Black;
  transition: variables.$transition-fast;
  font-family: variables.$font-heading;
  text-transform: uppercase;
  font-size: 12px;

  span {
    margin-right: 4px;

    @media (max-width: variables.$breakpoint-xs) {
      display: none;
    }
  }
}
.toaster__bottom {
  max-height: 0;
  overflow: hidden;
  transition: variables.$transition-fast;
}
.toaster__bottom__inner {
  padding: 0 16px 24px 60px;

  .arrow-link {
    margin-top: 24px;
  }
}
