@use "variables.scss";

.networkgraph {
  position: relative;
  background-image: url("/assets/images/networkgraph/background.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 102% 102%;

  &--container {
    position: relative;
    &__animate {
      animation: 1s fadeIn forwards;
    }
  }
}

.graph-callout {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  row-gap: 8px;
  background: #ffffff;
  border: 1px solid #e6e9eb;
  border-radius: 8px;
  padding: 8px;

  h1,
  span {
    color: variables.$BlackMedium;
    line-height: 1;
    font-size: 12px;
    font-weight: 400;
    margin: 0;
    padding: 0;
  }

  h1 {
    font-size: 14px;
    font-weight: 600;
    color: variables.$Black;
  }

  .loading {
    width: 100px;
    height: 20px;
    background-color: #e6e9eb;
    background: linear-gradient(-45deg, #e6e9eb, #ffffff, #e6e9eb);
    background-size: 200% 100%;
    animation: 0.7s loading linear infinite;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes loading {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 0%;
  }
}
