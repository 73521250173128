@use "sass:color";
@use "variables.scss";

button {
  border: 0;
  appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  margin: 0;
}
.button {
  display: inline-flex;
  align-items: center;
  border-radius: variables.$border-radius;
  background-color: variables.$SurfBlue100;
  padding: 0 16px;
  height: 40px;
  line-height: 40px;
  color: white;
  text-transform: uppercase;
  font-family: variables.$font-heading;
  text-decoration: none;
  font-size: 14px;
  transition: variables.$transition-fast;
  letter-spacing: -0.2px;
  position: relative;
  cursor: pointer;
  font-weight: 600;

  &--small {
    height: 32px;
    line-height: 32px;
    font-size: 12px;
    padding: 0 12px;

    .button__icon,
    .button__icon svg,
    .button__loader,
    .button__loader svg {
      width: 16px;
      height: 16px;
    }
    .button__loader {
      top: 10px;
    }

    .button__icon + span {
      padding-right: 4px;
    }
  }

  &--large {
    height: 48px;
    line-height: 48px;
    padding: 0 24px;

    .button__icon,
    .button__icon svg {
      width: 24px;
      height: 24px;
    }
    .button__icon {
      margin-right: 12px;
      margin-left: -8px;
    }
  }

  &--wide {
    width: 100%;
    justify-content: center;
  }

  &:hover {
    background-color: color.adjust(variables.$SurfBlue100, $lightness: 5%);
  }

  &:focus {
    outline: 0;
  }
}
.button--disabled {
  opacity: 0.25;
  pointer-events: none;
}
.button--filter {
  margin-bottom: 24px;
  display: none;

  @media (max-width: variables.$breakpoint-m-2) {
    display: inline-flex;
  }
  .button__icon:nth-child(2) {
    display: none;
  }
  span:nth-child(4) {
    display: none;
  }

  &.clicked {
    .button__icon:nth-child(2) {
      display: block;
    }
    span:nth-child(4) {
      display: block;
    }
    .button__icon:nth-child(1) {
      display: none;
    }
    span:nth-child(3) {
      display: none;
    }
  }
}
.button__loader,
.button__loader svg {
  width: 16px;
  height: 16px;
}
.button__loader {
  position: absolute;
  top: 12px;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: flex !important;
  color: white;
  opacity: 0;
}
.button.loading {
  color: transparent;
  pointer-events: none;

  &:hover {
    background-color: variables.$SurfBlue100;
  }

  .button__loader {
    opacity: 0.75;
    animation: 0.7s loader linear infinite;
  }
}
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.button__icon,
.button__icon svg {
  width: 16px;
  height: 16px;
}

.button__icon {
  margin-right: 8px;
}
.button__icon svg {
  display: block;
}
.button__flex {
  display: flex;
  flex-wrap: wrap;

  .button {
    margin-left: 8px;
  }
}
.button--red {
  background-color: variables.$SurfRed100;

  &:hover {
    background-color: variables.$SurfRed75;
  }
}
.button--green {
  background-color: variables.$StatusGreen;

  &:hover {
    background-color: color.adjust(variables.$StatusGreen, $lightness: 5%);
  }
}
.button--secondary {
  background-color: white;
  color: variables.$Black;
  border: 1px solid variables.$GreyMedium;

  &:hover {
    background-color: transparent;
    border-color: variables.$Grey;
  }
}
