@use "variables.scss";

.footer {
  padding: 24px 0;
  display: flex;
  align-items: center;

  @media (max-width: variables.$breakpoint-xs) {
    flex-direction: column-reverse;
    padding-top: 8px;
  }
}
.footer__navigation {
  height: 32px;
  display: flex;
  align-items: center;
  border-left: 2px solid variables.$GreyMedium;
  margin-left: 24px;

  @media (max-width: variables.$breakpoint-xs) {
    border: 0;
    margin: 0;
  }
}
.footer__link {
  margin-left: 24px;
  font-family: variables.$font-heading;
  text-transform: uppercase;
  text-decoration: none;
  color: variables.$Black;
  font-size: 12px;
  font-weight: 600;
  transition: variables.$transition-fast;

  &--active {
    color: variables.$SurfBlue100;
  }

  @media (max-width: variables.$breakpoint-xs) {
    margin: 0 12px;
  }

  &:hover {
    color: variables.$SurfBlue100;
  }
}
.footer__copyright {
  @media (max-width: variables.$breakpoint-xs) {
    padding-top: 16px;
  }
}
