@use "variables.scss";

.mat-sort-header-arrow {
  color: variables.$SurfBlue100;
}

.mat-sort-header {
  &:hover .mat-sort-header-arrow {
    color: variables.$Black;
  }

  &:hover .mat-sort-header-sorted .mat-sort-header-arrow {
    color: variables.$SurfBlue100;
  }
}
