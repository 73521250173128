@use "variables.scss";

.pie__container {
  max-width: 330px;
  display: block;
  margin: 0 auto;
  position: relative;
}
.pie__container--large {
  max-width: 500px;
}
.pie__inner {
  position: absolute;
  left: 50%;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  max-width: 190px;
  text-align: center;
}
.pie__inner__item {
  &:not(:first-child) {
    margin-top: 16px;
  }
}
.pie__inner__small {
  font-size: 11px;
  line-height: 24px;
}
.pie__inner__big {
  font-size: 16px;
  color: variables.$Black;
  font-family: variables.$font-heading;
}
.pie__inner__redlabel {
  color: variables.$StatusRed;
}
.highcharts-point {
  transition: opacity 0.2s;
}
.highcharts-series-hover .highcharts-point {
  opacity: 0.5;
}
.highcharts-series-hover .highcharts-point-hover {
  opacity: 1;
}

.pie__input {
  width: 120px;
  font-size: 48px;
  border: 0;
  border-bottom: 1px solid variables.$GreyMedium;
  font-family: variables.$font-heading;
  text-align: center;
  color: variables.$Black;
  appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  border-radius: 0;
}

.pie__input::-webkit-inner-spin-button,
.pie__input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  margin: 0;
}

@keyframes pieDynamic {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.pie__radio-wrapper {
  display: flex;
  justify-content: center;
  padding-top: 8px;
}
.pie__radio {
  input[type="radio"] {
    opacity: 0;
    position: absolute;
    height: 1px;
    width: 1px;

    + label {
      font-family: variables.$font-heading;
      font-size: 24px;
      cursor: pointer;
      color: variables.$Grey;
    }

    &:checked + label {
      color: variables.$SurfBlue100;
    }
  }

  &:not(:last-child):after {
    content: "/";
    display: inline-block;
    vertical-align: text-bottom;
    margin: 0 8px;
    font-size: 20px;
    color: variables.$Grey;
  }
}
.pie__dynamic {
  .highcharts-point {
    opacity: 0.3;

    &:nth-last-child(1) {
      opacity: 1;
    }
    &:nth-last-child(2) {
      opacity: 1;
      animation: 2s pieDynamic infinite;
    }
  }
}
