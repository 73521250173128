@use "variables.scss";

.service-summary {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;

  @media (max-width: variables.$breakpoint-xs) {
    flex-direction: column;
  }

  &--force-column {
    flex-direction: column;

    .service-summary__speed {
      margin-top: 32px;
      margin-left: 0;
      padding: 0;
    }
  }

  &__main {
    width: 100%;
  }

  &__speed {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    height: 100%;
    padding: 16px;
    margin-left: 32px;
    margin-top: 0;

    @media (max-width: variables.$breakpoint-xs) {
      margin-top: 32px;
      margin-left: 0;
      padding: 0;
    }

    &--block {
      background: variables.$WhiteCream;
      border-radius: 4px;
      padding: 16px;
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 16px;

      .up {
        transform: rotate(-90deg);
      }

      .down {
        transform: rotate(90deg);
      }
    }

    &--block-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 14px;
      color: variables.$BlackMedium;
      column-gap: 4px;
    }

    &--block-speed {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      font-family: variables.$font-heading;
      font-size: 12px;
      font-weight: 800;
      color: variables.$Black;
      column-gap: 4px;

      span {
        margin-bottom: 4px;
      }

      span:first-child {
        font-size: 24px;
        margin-bottom: 0;
      }
    }
  }

  &__header {
    display: flex;
    margin-bottom: 16px;
  }
  &__header-right {
    padding-left: 16px;
  }

  &__icon-overlap-square {
    position: absolute;
    bottom: -8px;
    right: -8px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: variables.$border-radius;
    background-color: variables.$SurfRed25;
    box-shadow: 0 4px 6px -1px rgba(black, 0.1);

    svg-icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    svg {
      width: 24px !important;
      height: 24px !important;
      display: flex;
      color: variables.$SurfRed75 !important;
    }
  }
  &__icon {
    position: relative;
    flex-shrink: 0;
    width: 56px;
    height: 56px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;

    &--lightpath,
    &--l2vpn {
      background: variables.$SurfGreen25;
      svg-icon {
        color: variables.$SurfGreen100;
      }
    }
    &--internet,
    &--ip {
      background: variables.$SurfBlue25;
      svg-icon {
        color: variables.$SurfBlue100;
      }
    }
    &--l3vpn {
      background: variables.$SurfOrange25;
      svg-icon {
        color: variables.$SurfOrange100;
      }
    }
    &--port {
      background: variables.$SurfPurple25;
      svg-icon {
        color: variables.$SurfPurple100;
      }
    }
    &--fw {
      background: variables.$SurfRed25;
      svg-icon {
        color: variables.$SurfRed75;
      }
    }
    &--grey {
      background: variables.$GreyLight;
      svg-icon {
        color: variables.$BlackLight;
      }
    }

    svg-icon {
      flex-shrink: 0;
      width: 32px;
      height: 32px;
    }

    svg-icon svg {
      width: 32px;
      height: 32px;
    }
  }

  &__title-wrapper {
    display: flex;
    margin-bottom: 8px;

    svg-icon {
      display: flex;
    }
  }

  &__title {
    margin: 0 0 0 8px;
  }

  &__bottom {
    margin-top: 24px;
  }

  &__table-row {
    display: flex;
    padding: 12px 0;

    &:not(:last-child) {
      border-bottom: 1px solid variables.$GreyMedium;
    }
  }
  &__table-col {
    &:nth-child(1) {
      width: 30%;
      font-weight: bold;
      color: variables.$Black;
      flex-shrink: 0;
    }
    &:nth-child(2) {
      flex-grow: 1;
    }
    &--flex-newline {
      display: flex;
      flex-direction: column;
    }
  }

  &__scroll-button {
    margin-right: 16px;
  }
}
