@use "variables.scss";

.checktag__flex {
  display: flex;
  flex-wrap: wrap;
}
.checktag {
  background-color: variables.$GreyMedium;
  border-radius: variables.$border-radius-s;
  font-family: variables.$font-heading;
  height: 24px;
  line-height: 24px;
  color: variables.$Black;
  padding: 0 8px;
  margin: 0 8px 8px 0;
  cursor: pointer;
  transition: variables.$transition-fast;
  font-weight: 600;

  &:after {
    content: "";
    position: relative;
    top: -1px;
    vertical-align: middle;
    width: 12px;
    height: 12px;
    margin-left: 4px;
    background-size: 100%;
    background-image: url("/assets/images/icons/x-white.svg");
    display: none;
  }

  &:not(.active):hover {
    background-color: variables.$Grey;
  }

  &.active {
    color: white;
    background-color: variables.$SurfBlue100;
    transition: 0s;

    &:after {
      display: inline-block;
    }
  }
}
