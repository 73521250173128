@use "variables.scss";

::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
::-webkit-search-results-button {
  -webkit-appearance: none;
}
completer {
  @media (max-width: variables.$breakpoint-xs) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    display: none;
  }
}
.completer__flex {
  display: flex;
  position: relative;
}

.completer-input {
  height: 48px;
  box-shadow: variables.$box-shadow-light;
  appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  border: 0;
  border-radius: variables.$border-radius 0 0 variables.$border-radius;
  border: 1px solid variables.$GreyMedium;
  padding: 0 16px;
  font-family: variables.$font-normal;
  font-size: 13px;
  width: 232px;
  -webkit-font-smoothing: antialiased;
  transition: border-color 0.2s ease;

  &::placeholder {
    color: variables.$BlackMedium;
  }

  @media (max-width: variables.$breakpoint-m-1) {
    width: 100px;
  }
}

.completer__search {
  width: 100%;
}
.completer__search .completer-input {
  border-radius: variables.$border-radius;
  width: 100%;
  background-image: url("/assets/images/icons/search.svg");
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: right 16px center;
  &.ng-dirty {
    border-radius: variables.$border-radius variables.$border-radius 0 0;
  }
}

.completer__button {
  background-color: variables.$SurfBlue100;
  border: 0;
  color: white;
  appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  text-transform: uppercase;
  font-family: variables.$font-heading;
  padding: 0 12px;
  height: 48px;
  text-decoration: none;
  border-radius: 0 variables.$border-radius variables.$border-radius 0;
  transition: variables.$transition-fast;
  display: flex;
  align-items: center;
  font-size: 14px;
  box-shadow: variables.$box-shadow-light;
  cursor: pointer;
  color: variables.$SurfBlue25;

  span {
    margin-left: 8px;
  }

  &:hover {
    color: white;
  }
}
.completer-input {
  position: relative;
  z-index: 2;
}
.completer-dropdown-holder {
  position: relative;
  z-index: 1;
}
.completer-dropdown {
  border-color: variables.$GreyMedium !important;
  border-width: 1px !important;
  border-style: solid;
  border-radius: 0 0 variables.$border-radius variables.$border-radius !important;
  width: 100% !important;
  padding: 0 16px;
  z-index: 0 !important;
  position: absolute;
  background-color: #ffffff;
  animation: 0.2s completerDropdown forwards;
  font-family: variables.$font-normal !important;
  top: -1px;
}
@keyframes completerDropdown {
  0% {
    opacity: 0;
    transform: translateY(-16px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.completer-row,
.completer-no-results {
  padding: 0 12px 0 12px !important;
  height: 32px;
  line-height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: variables.$Black !important;
  margin-bottom: 0 !important;
  clear: both;
  display: inline-block;
  width: 100% !important;
  font-family: variables.$font-normal;
  transition: 0.2s ease;
}

.completer-selected-row {
  background-color: variables.$SurfBlue25 !important;
  color: variables.$Black !important;
}

.completer-checkbox {
  margin: 0;
  margin-right: 8px;
  min-width: 24px;

  // new style
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.match {
  font-weight: bold;
}
input[type="search"] {
  -webkit-appearance: textfield;
}

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
.completer__icon,
.completer__icon svg {
  width: 24px;
  height: 24px;
}
.completer__list {
  padding-top: 16px;
  li {
    display: block;
  }
}
@keyframes completerSelected {
  0% {
    opacity: 0;
    transform: translateY(-16px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: white;
  border: 1px solid variables.$Grey;
  border-radius: variables.$border-radius;

  svg-icon {
    color: white;
    width: 16px;
    height: 16px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.completer__container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;

  // new style
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover input ~ .checkmark {
    border-color: variables.$BlackLight;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  input:checked ~ .checkmark {
    border-color: variables.$SurfBlue100;
    background-color: variables.$SurfBlue100;
  }
}

.completer__label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  line-height: 20px;
}
.completer__selected {
  animation: completerSelected 0.3s forwards;

  &:hover {
    .completer__x {
      color: variables.$SurfBlue100;
    }
  }
}
.completer__x {
  position: relative;
  top: -1px;
  margin-left: 4px;
  color: variables.$Black;
  transition: variables.$transition-fast;
}
.completer__x,
.completer__x svg {
  width: 12px;
  height: 12px;
}

.completer-bottom-results {
  margin-top: 1rem;
}

.completer-bottom-result {
  font-family: variables.$font-heading;
  color: variables.$Black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 8px;

  .test::first-letter {
    text-transform: uppercase;
  }

  &:hover svg-icon {
    color: variables.$SurfBlue100;
  }

  svg-icon {
    display: flex;
    flex-shrink: 0;
    transition: all 0.2s ease;
  }
}
