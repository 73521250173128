@use "variables.scss";

dialog {
  display: block;
  position: relative;
  border: 0;
  padding: 0;
}

body .mat-dialog-container {
  border-radius: variables.$border-radius;
  padding: 0;
  box-shadow: none;
}

.dialog__header {
  padding: 20px 24px;
  border-bottom: 1px solid variables.$GreyMedium;
  background-color: variables.$WhiteCream;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dialog {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 80vw;
  color: variables.$BlackMedium;
  max-width: 720px;
  position: relative;
  min-height: 640px;

  &--small {
    max-width: 640px;

    .dialog__body {
      padding: 32px 48px;

      @media (max-width: variables.$breakpoint-xs) {
        padding: 32px 16px;
      }
    }
  }

  &--xs {
    max-width: 540px;
  }
}
.dialog__error {
  background-color: variables.$StatusRedLight;
  color: variables.$StatusRed;
  padding: 8px 16px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  margin-top: 16px;

  &--margin-x {
    margin-left: 24px;
    margin-right: 24px;
  }
}
.dialog__step {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;

  &--center {
    justify-content: center;
  }
}
.dialog__body {
  padding: 24px;

  &--grow {
    flex-grow: 1;
  }

  &--center {
    display: flex;
    align-items: center;
  }

  &--grey {
    background-color: variables.$WhiteCream;
  }

  &--no-padding {
    padding: 0;
  }
}
.dialog__grow-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.dialog__body--scroll {
  max-height: 66vh;
  overflow-y: scroll;
  padding: 0;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
.dialog__inner {
  padding: 20px 24px;
}

.dialog__rule {
  background-color: white;
  padding: 12px 24px;
  border-top: 1px solid variables.$GreyMedium;
  border-bottom: 1px solid variables.$GreyMedium;
  margin-bottom: 8px;
}

.dialog__port-summary {
  background-color: white;
  padding: 0 24px;
  border-top: 1px solid variables.$GreyMedium;
  border-bottom: 1px solid variables.$GreyMedium;
  border-bottom: 1px solid variables.$GreyMedium;
  margin-bottom: 16px;
}

.dialog__port-icon {
  color: variables.$SurfPurple100;
  margin-right: 8px;
}

.dialog__port-summary-item {
  display: flex;
  align-items: center;
  color: variables.$Black;
  padding: 20px 0;

  &:not(:last-child) {
    border-bottom: 1px solid variables.$GreyMedium;
  }
}

.dialog__port-summary-left {
  display: flex;
  padding-right: 16px;
  flex-grow: 1;

  &--center {
    align-items: center;
  }
}

.dialog__port-summary-right {
  display: flex;
  align-items: center;

  svg-icon {
    margin: 0 16px;
  }
}

.dialog__port-summary-right-flex {
  display: flex;
  align-items: center;
}

.dialog__rule-answer {
  font-weight: 500;
  line-height: 20px;
  font-size: 14px;
}

.dialog__footer {
  padding: 24px;
  display: flex;
  justify-content: space-between;

  &--border {
    border-top: 1px solid variables.$GreyMedium;
  }

  @media (max-width: variables.$breakpoint-xs) {
    padding: 24px 16px;
  }

  &-text {
    font-size: 12px;
    line-height: 1.7;

    .green {
      font-weight: 600;
    }
  }

  &--center {
    align-items: center;
  }
}
.dialog__footer--end {
  justify-content: flex-end;
}

.dialog__close {
  color: variables.$Black;
  cursor: pointer;

  &--absolute-right {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  &:hover {
    color: variables.$SurfBlue100;
  }
}

.dialog__title {
  margin: 0 !important;
  transition: variables.$transition-fast;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-weight: 600;
}

.dialog__title-suffix {
  margin-left: 4px;
}

.dialog__title-light {
  font-weight: 300;
}
.dialog__icon,
.dialog__icon svg {
  width: 20px;
  height: 20px;
}
.dialog__icon {
  margin-right: 8px;
}

.dialog__tabs {
  background-color: variables.$Grey;
  font-size: 11px;
  display: flex;
}

.dialog__tab {
  flex-grow: 1;
  border-right: 1px solid variables.$GreyMedium;
  font-family: variables.$font-heading;
  text-align: center;
  height: 32px;
  line-height: 32px;
  padding: 0 8px;
  max-width: 50%;
  transition: variables.$transition-fast;

  &.active {
    background-color: variables.$GreyLight;
  }

  &:not(.active):hover {
    background-color: variables.$GreyMedium;
    cursor: pointer;
  }

  span {
    display: block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.dialog__bottom-message {
  padding: 0 24px 16px 24px;
  background-color: variables.$WhiteCream;
}

.dialog__bottom-message-inner {
  border-radius: 4px;
  background-color: variables.$SurfBlue25;
  color: variables.$SurfBlue100;
  font-weight: 600;
  padding: 12px 12px 12px 8px;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;

  svg-icon {
    margin-right: 8px;
    flex-shrink: 0;
  }
}

.dialog__top {
  text-align: center;
  padding: 24px 32px 0 32px;
}
.dialog--order .dialog__close {
  position: absolute;
  top: 24px;
  right: 24px;
}
.dialog--order {
  padding: 16px;
}
.dialog__heading {
  margin: 0 auto;
}

.dialog__sub-heading {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  margin: 0 0 4px 0;
}

.dialog__port-rule {
  padding: 20px 24px;
  border-bottom: 1px solid variables.$GreyMedium;
  background-color: white;
  display: flex;
  align-items: flex-start;
  color: variables.$Black;

  .dialog__port-subtext {
    margin-top: 4px;
  }

  .select-wrapper {
    width: 200px;
  }

  &--flex {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div:first-child {
      display: flex;
    }
  }

  + .dialog__port-rule {
    margin-top: 8px;
  }
}

.dialog__port-number {
  width: 20px;
  height: 20px;
  border: 1px solid variables.$GreyMedium;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: variables.$font-heading;
  flex-shrink: 0;
  border-radius: 4px;
  font-weight: 700;
  font-size: 10px;
  margin-right: 8px;
}

.dialog__port-text {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  font-family: variables.$font-heading;
  color: variables.$Black;
  flex-grow: 1;
}

.dialog__port-subtext {
  font-size: 12px;
  color: variables.$BlackMedium;
  line-height: 16px;
}

.dialog__label {
  margin-bottom: 8px;
}
@media (max-width: variables.$breakpoint-xs) {
  .dialog {
    width: 100vw;
    min-height: 0;
  }
  .dialog__header {
    padding: 16px;
  }
  .dialog__inner {
    padding: 16px;
  }
  .cdk-overlay-pane {
    max-width: none !important;
  }
  .cdk-global-overlay-wrapper {
    align-items: flex-end !important;
  }
  .dialog .service__flex--top {
    flex-direction: column-reverse;
  }
  .dialog__icon + span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    max-width: 80vw;
  }
}
@media (max-width: 340px) {
  .dialog__icon + span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    max-width: 232px;
  }
}

.dialog-loading-banner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  & > span {
    font-weight: bold;
    font-size: 20px;
    color: variables.$White;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: variables.$SurfBlue50;
    border: 1px solid variables.$SurfBlue100;
    border-radius: 4px;
    padding: 8px;
  }
}
