@use "variables.scss";

.progress__title {
  font-size: 11px;
  margin-bottom: 8px;
}
.progress__bar {
  display: block;
  height: 8px;
  position: relative;
  border-radius: 8px;
  background-color: variables.$GreyLight;
  overflow: hidden;
  width: 360px;
  max-width: 100%;
}

.progress__value {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  transition: variables.$transition-fast;
  background-color: variables.$SurfBlue100;
}
