@use "variables.scss";

.tile {
  padding: 16px;
  border: 1px solid variables.$GreyMedium;
  border-radius: variables.$border-radius-l;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: variables.$transition-fast;

  .pie__container {
    pointer-events: none;
  }

  &--active {
    border: 2px solid variables.$SurfBlue100;

    &:hover {
      border-color: variables.$SurfBlue100;
    }
  }

  + .tile {
    margin-top: 16px;
  }

  h3 {
    margin: 0 0 8px 0;
  }

  &:hover {
    border-color: variables.$BlackMedium;

    .tile__icon svg {
      color: variables.$SurfBlue100;
    }
  }
}
.tile__icon,
.tile__icon svg {
  width: 64px;
  height: 64px;
  color: variables.$GreyMedium;
  margin: 0 0 16px 0;
  transition: variables.$transition-fast;
}

.tile__row {
  font-size: 11px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}
