@use "variables.scss";

.overflow-menu {
  position: relative;

  &__items {
    position: absolute;
    right: 0;
    top: calc(100% - 3px);
    border: 1px solid variables.$GreyMedium;
    border-radius: 4px;
    padding: 6px 0;
    width: 200px;
    background-color: white;
    opacity: 0;
    visibility: hidden;
    box-shadow:
      0 2px 4px -1px rgba(black, 0.07),
      0 6px 15px -4px rgba(black, 0.15);
    transform: translateY(-4px);
    transition: all 0.3s ease;
  }
  &__item {
    background-color: white;
    font-size: 14px;
    line-height: 24px;
    padding: 6px 24px 6px 12px;
    white-space: nowrap;
    width: 100%;
    cursor: pointer;
    transition: all 0.2s ease;
    color: variables.$Black;

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &:hover {
      background-color: variables.$WhiteCream;
    }
  }

  &__button.active + .overflow-menu__items {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
}
