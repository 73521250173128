@use "_type.scss";
@use "variables.scss";

.tabs {
  &__nav-wrapper {
    display: flex;
    border-bottom: 1px solid variables.$GreyMedium;

    .tabs__nav {
      border-bottom: 0 !important;
    }
  }
  &__nav {
    flex-grow: 1;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    counter-reset: tabs;
    scroll-behavior: smooth;
    border-bottom: 1px solid variables.$GreyMedium;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__nav-item {
    flex-grow: 1;
    display: flex;
    padding: 16px;
    cursor: pointer;
    position: relative;
    min-width: 28%;

    @media (max-width: variables.$breakpoint-xs) {
      min-width: 300px;
    }

    &:not(:last-child) {
      border-right: 1px solid variables.$GreyMedium;
    }

    &:nth-last-child(2) {
      border-right: 0;
    }

    &:only-child {
      flex-grow: 0;
    }

    &.active {
      &:after {
        background-color: variables.$SurfBlue100;
      }

      &:before {
        background-color: variables.$SurfBlue100;
        border-color: variables.$SurfBlue100;
        color: white;
      }
    }

    &:after {
      content: "";
      position: absolute;
      height: 2px;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: transparent;
    }

    &:before {
      counter-increment: tabs;
      content: counter(tabs);
      margin-top: 2px;
      width: 20px;
      height: 20px;
      border: 1px solid variables.$GreyMedium;
      font-weight: bold;
      font-family: variables.$font-heading;
      border-radius: variables.$border-radius;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      font-size: 10px;
      flex-shrink: 0;
    }
  }
  &__nav-caption {
    display: flex;
    flex-direction: column;
    min-width: 0;
  }
  &__nav-title {
    font-family: variables.$font-heading;
    color: variables.$Black;
    font-weight: 600;
    @include type.type-300;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__subtitle,
  &__nav-subtitle {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__content {
    display: flex;
    padding: 48px;
    flex-wrap: wrap;

    @media (max-width: variables.$breakpoint-s-2) {
      padding: 24px;
    }
  }

  &__content-left {
    width: 50%;
    padding-right: 48px;

    @media (max-width: variables.$breakpoint-s-2) {
      width: 100%;
      padding-right: 0;
      margin-bottom: 32px;
    }
  }

  &__content-right {
    width: 50%;

    @media (max-width: variables.$breakpoint-s-2) {
      width: 100%;
    }
  }

  &__table {
    display: flex;
    flex-direction: column;
  }
  &__table-row {
    display: flex;
    border-radius: 4px;
    padding: 8px 16px;

    &:nth-child(odd) {
      background-color: variables.$WhiteCream;
    }
  }

  &__table-col {
    width: 50%;
    @include type.type-300;

    &:nth-child(1) {
      padding-right: 16px;
      font-weight: 700;
      color: variables.$Black;
    }
  }

  &__overflow-box {
    height: 112px;
    overflow-y: auto;
    border-radius: 4px;
    border: 1px solid variables.$GreyMedium;
    background-color: white;
    padding: 8px;
  }

  &__grid {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  &__grid-item {
    width: 50%;
    border-right: 1px solid variables.$GreyMedium;
    border-bottom: 1px solid variables.$GreyMedium;
    padding: 40px 48px;

    &:nth-child(1),
    &:nth-child(2) {
      padding-top: 0;
    }

    &:nth-child(odd) {
      padding-left: 0;
    }
    &:nth-child(even) {
      padding-right: 0;
      border-right: 0;
    }

    &:nth-last-child(-n + 2) {
      border-bottom: 0;
      padding-bottom: 0;
    }

    @media (max-width: variables.$breakpoint-xs) {
      width: 100%;
      border-right: 0;
      padding: 32px 0 !important;
    }

    &:only-child {
      width: 100%;
      border: 0;
    }

    &.wide {
      width: 100%;
      border: 0;
      display: flex;
      padding-right: 0;

      @media (max-width: variables.$breakpoint-xs) {
        flex-wrap: wrap;
      }
    }
  }

  &__grid-prefixes {
    width: 50%;
    padding-left: 40px;
    flex-shrink: 0;

    @media (max-width: variables.$breakpoint-xs) {
      width: 100%;
      padding-left: 0;
      padding-top: 32px;
    }
  }

  &__controls {
    display: flex;
    background-color: white;
    position: sticky;
    right: 0;
    border-left: 1px solid variables.$GreyMedium;
  }

  &__control {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
    cursor: pointer;

    &--loader {
      padding: 0;
      margin: 0;

      .loader {
        padding: 0 !important;
        margin: 16px;
      }
    }

    &:not(:last-child) {
      border-right: 1px solid variables.$GreyMedium;
    }
  }

  &__flex-center {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > * {
      margin-bottom: 16px;
    }
  }
}
