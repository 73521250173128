@use "variables.scss";

// Typography functions
@function calculateRem($size) {
  $remSize: calc($size / 16px);
  @return $remSize * 1rem;
}

// Utility mixins
@mixin font-size($size) {
  font-size: $size; // fallback
  font-size: calculateRem($size);
}

@mixin font-heading() {
  font-family: variables.$font-heading;
}

@mixin proxima() {
  font-family: "Proxima";
}
/* Typography mixins

- Type-50 Usage: chart text (on axis), chart tooltip text etc.
- Type-100-tight Usage: small detail text, small list items etc
- Type-100 - Usage: small detail text, captions etc
- Type-200 - Usage: tag-labels
- Type-300-tight - Usage: short body text, single-line items
- Type-300 - Usage: Default body text, subtitles, button labels etc.
- Type-400-loose - Usage: long-read body text
- Type-400 - Usage: Subtitles, card titles, short body text
- Type-500 - Usage: In-page headings, semi large headings
- Type-600 - Usage: Page titles, large headings

*/
@mixin type-label() {
  @include proxima();
  @include font-size(12px);
  line-height: 1.666666667;
  text-transform: uppercase;
  font-weight: 300;
}
@mixin type-label-strong() {
  @include proxima();
  @include font-size(12px);
  line-height: 1.666666667;
  font-weight: 600;
  text-transform: uppercase;
}
@mixin type-50() {
  @include font-size(11px);
  line-height: 1.454545455;
}

@mixin type-100() {
  @include font-size(12px);
  line-height: 1.666666667;
}
@mixin type-100-tight() {
  @include font-size(12px);
  line-height: 1.333333333;
}
@mixin type-200() {
  @include font-size(13px);
  line-height: 1.54;
}
@mixin type-300() {
  @include font-size(14px);
  line-height: 1.714285714;
}
@mixin type-300-tight() {
  @include font-size(14px);
  line-height: 1.428571429;
}
@mixin type-400() {
  @include font-size(16px);
  line-height: 1.5;
}
@mixin type-400-loose() {
  @include font-size(16px);
  line-height: 1.75;
}
@mixin type-500() {
  @include font-size(20px);
  letter-spacing: -0.025em;
  line-height: 1.4;
}

@mixin type-600() {
  @include font-size(24px);
  letter-spacing: -0.025em;
  @media (max-width: variables.$breakpoint-xs) {
    @include font-size(18px);
  }
}

@mixin link() {
  @include type-300();
  text-transform: uppercase;
  color: variables.$SurfBlue100;
  font-weight: bold;
  text-decoration: none;
  transition: 0.2s opacity ease;
  &:hover {
    opacity: 0.8;
  }
}

// Typography declarations
h1 {
  font-size: 32px;
  line-height: 40px;
  color: variables.$Black;
  margin: 0 0 16px 0;
}
h2 {
  color: variables.$Black;
  margin: 0 0 16px 0;
  @include type-600();
}
h3 {
  @include type-400();
  color: variables.$Black;
  margin: 0 0 16px 0;
  font-weight: 700;
}
h4 {
  @include type-200();
  color: variables.$Black;
  margin: 0 0 16px 0;
}
h5 {
  @include type-50();
}
h6 {
  font-size: 9px;
  line-height: 16px;
}
ol {
  list-style-type: decimal;
  padding-left: 16px;
  line-height: 1.8;
}
li strong {
  font-family: variables.$font-heading;
  font-weight: normal;
  color: variables.$Black;
}
p {
  font-size: 14px;
  line-height: 24px;
  margin: 0 0 16px 0;
  color: variables.$Black;

  strong {
    font-family: variables.$font-heading;
    font-weight: normal;
    color: variables.$Black;
  }

  &.small {
    @include type-50();
  }
  &.large {
    @include type-500();
  }

  a {
    color: variables.$SurfBlue100;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
sup {
  vertical-align: text-top;
}
hr {
  border: 0;
  border-top: 1px solid variables.$GreyLight;
  margin: 16px 0;
}

.arrow-link__icon {
  width: 16px;
  height: 16px;
  margin-left: 5px;
  transition: variables.$transition-fast;
}
.arrow-link {
  font-family: variables.$font-heading;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  transition: variables.$transition-fast;

  span,
  .arrow-link__icon {
    color: variables.$Black;
    transition: variables.$transition-fast;
    transform: translateZ(0);
  }

  &:hover {
    color: variables.$SurfBlue100;

    span,
    .arrow-link__icon {
      color: variables.$SurfBlue100;
    }
    .arrow-link__icon {
      transform: translateX(3px) translateZ(0);
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include font-heading();
}

.list {
  font-size: 14px;
  font-family: variables.$font-heading;
  color: variables.$Black;
  text-transform: uppercase;
  line-height: 24px;

  a {
    font-size: 14px;
    text-transform: uppercase;
    color: variables.$SurfBlue100;
    font-family: variables.$font-heading;
    text-decoration: none;
    line-height: 24px;
    transition: variables.$transition-fast;

    &:hover {
      color: variables.$SurfBlue75;
    }
  }
}

.blue-link {
  font-size: 14px;
  text-transform: uppercase;
  color: variables.$SurfBlue100;
  font-family: variables.$font-heading;
  text-decoration: none;
  line-height: 24px;
  transition: variables.$transition-fast;
  cursor: pointer;
  font-weight: 600;

  &:hover {
    color: variables.$SurfBlue75;
  }
}

// Class declarations
.type {
  &-50 {
    @include type-50();
  }
  &-100 {
    @include type-100();
    &-tight {
      @include type-100-tight();
    }
  }
  &-200 {
    @include type-200();
  }
  &-300 {
    @include type-300();
    &-tight {
      @include type-300-tight();
    }
  }
  &-400 {
    @include type-400();
    &-loose {
      @include type-400-loose();
    }
  }
  &-500 {
    @include type-500();
  }
  &-600 {
    @include type-600();
  }
  &-label {
    @include type-label();
  }
  &-label-strong {
    @include type-label-strong();
  }
}

.title-with-icon {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  svg-icon {
    display: flex;
    margin-right: 8px;
    flex-shrink: 0;
  }
}

.title-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;
}
