@use "variables.scss";

.gauge__wrapper {
  text-align: center;
  display: block;
  margin-bottom: 60px;
}
.gauge {
  display: block;
  width: 100%;
  height: auto;
}
.gauge__title {
  margin-bottom: 0;
}
.gauge__subtitle {
  margin-bottom: 16px;
}
.gauge__container {
  padding: 0 40px;
  max-width: 256px;
  max-height: 88px;
  position: relative;
  margin: 0 auto 32px auto;
  display: block;
}
.gauge__value {
  position: absolute;
  top: 100%;
  padding-top: 16px;
  left: 0;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  color: variables.$Black;
  font-family: variables.$font-heading;
  text-align: center;
}
.gauge__pointer {
  transform-origin: center center;
  transition: variables.$transition-fast;
}

.gauge__legend {
  position: absolute;
  font-family: variables.$font-heading;
  color: rgba(variables.$Black, 0.4);
  font-size: 9px;
}

.gauge__legend--1 {
  top: 100%;
  left: 0;
}
.gauge__legend--2 {
  bottom: 32px;
  right: 0;
}
