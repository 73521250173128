@use "variables.scss";

.flatpickr-calendar {
  border-radius: variables.$border-radius;
  border: 1px solid variables.$GreyMedium;
  box-shadow:
    0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.flatpickr-prev-month,
.flatpickr-next-month {
  color: transparent !important;
  width: 24px;
  height: 24px;
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
}
.flatpickr-prev-month {
  background-image: url("/assets/images/icons/arrow-small-left.svg");
}
.flatpickr-next-month {
  background-image: url("/assets/images/icons/arrow-small-right.svg");
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: variables.$SurfBlue100;
  border-color: variables.$SurfBlue100;
}
.flatpickr-day.today {
  border-color: variables.$Grey;

  &:hover {
    background-color: variables.$GreyMedium;
    border-color: variables.$Grey;
    color: variables.$Black;
  }

  &.selected:hover {
    background-color: variables.$SurfBlue100;
    color: variables.$White;
  }
}
.flatpickr-current-month {
  font-family: variables.$font-heading;
}
.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-current-month input.cur-year,
.flatpickr-day {
  font-weight: bold;
  color: variables.$BlackMedium;
}
.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-current-month input.cur-year {
  color: variables.$Black;
  font-size: 18px;
}
