@use "variables.scss";

.availability {
  padding: 32px;
  overflow-y: auto;

  @media (min-width: variables.$breakpoint-xs) {
    padding: 48px;
  }
}
