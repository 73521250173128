@use "variables.scss";

.message {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: variables.$BlackMedium;
  padding: 24px 0;
  border-bottom: 1px solid variables.$GreyMedium;
  /* cursor:pointer; */

  @media (max-width: variables.$breakpoint-xs) {
    padding: 16px 0;
  }

  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
    border: 0;
  }
}
.message__icon {
  width: 40px;
  height: 40px;
  border-radius: 99em;
  background-color: #eee;
  flex-shrink: 0;
  display: flex !important;
  align-items: center;
  justify-content: center;

  svg {
    width: 24px;
    height: 24px;
  }

  &--red {
    background-color: variables.$SurfRed25;
    color: variables.$SurfRed100;
  }
  &--orange {
    background-color: variables.$SurfOrange25;
    color: variables.$SurfOrange100;
  }
  &--blue {
    background-color: variables.$SurfBlue25;
    color: variables.$SurfBlue100;
  }
}
.message__caption {
  padding-left: 16px;
  width: calc(100% - 40px);
  cursor: pointer;
}
.message__rule {
  white-space: nowrap;
  max-width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.message__title {
  margin: 0;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;

  @media (max-width: variables.$breakpoint-xs) {
    font-size: 13px;
    line-height: 14px;
  }
}
.message__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}
.message__date {
  font-size: 11px;
  white-space: nowrap;
  padding-left: 8px;
}
