@use "variables.scss";

.carousel {
  margin: 0 -8px;
}
.carousel__col {
  width: 33.333%;
  padding: 0 8px;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  .tile {
    flex-grow: 1;
  }
}

/*! Flickity v2.0.10
http://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
  position: absolute;
  top: 50%;
  width: 48px;
  height: 48px;
  border: none;
  border-radius: 50%;
  background-color: white;
  border: 1px solid variables.$GreyMedium;
  background-position: center;
  background-size: 32px;
  background-repeat: no-repeat;
  color: variables.$Black;
  box-shadow: variables.$box-shadow;
  cursor: pointer;
  transition: 0.225s ease;
  transform: translateY(-50%) scale(1);
}

.flickity-prev-next-button:hover {
  border-color: variables.$Grey;
}
.flickity-prev-next-button.next {
  background-image: url("/assets/images/icons/arrow-small-right.svg");
  right: -16px;
}
.flickity-prev-next-button.previous {
  background-image: url("/assets/images/icons/arrow-small-left.svg");
  left: -16px;
}
.flickity-prev-next-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #09f;
}

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}
.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button:disabled {
  opacity: 0;
  cursor: auto;
  transform: translateY(-50%) scale(0);
}

.flickity-prev-next-button svg {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
  display: none;
}

.flickity-prev-next-button .arrow {
  fill: #333;
}

/* ---- page dots ---- */

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}
