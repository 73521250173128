@use "variables.scss";

.badge {
  display: block;
  border-radius: variables.$border-radius;
  background-color: variables.$SurfBlue25;
  color: variables.$SurfBlue100;
  font-family: variables.$font-heading;
  font-size: 16px;
  text-align: center;
  padding: 12px;
  line-height: 24px;

  @media (max-width: variables.$breakpoint-xs) {
    margin-bottom: 16px;
  }
}
