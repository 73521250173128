/* You can add global styles to this file, and also import other style files */
@use "assets/stylesheet/styles.scss";

html {
  scroll-behavior: smooth;
}

a.no-style-link,
a.no-style-link:hover,
a.no-style-link:active {
  text-decoration: none;
  color: inherit;
}
