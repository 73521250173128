@use "variables.scss";

.multiselect-dropdown {
  background-color: white;
  border-radius: 4px;
  height: 48px;
  box-shadow: 0 1px 2px 0 rgba(black, 0.06);

  .dropdown-btn {
    height: 48px;
    padding: 12px !important;
    border-color: variables.$GreyMedium !important;
  }

  .dropdown-list {
    box-shadow: none !important;
  }

  .selected-item {
    background: transparent !important;
    border: 0 !important;
    color: variables.$Black !important;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &:hover {
      box-shadow: none !important;
    }

    a {
      color: variables.$Black !important;
      display: none !important;
    }
  }

  .dropdown-multiselect__caret {
    height: 48px !important;
    transform: scale(0.75) !important;
  }

  .multiselect-item-checkbox input + div:before {
    border-radius: 2px !important;
  }

  .multiselect-item-checkbox input:checked + div:before {
    background-color: variables.$SurfBlue100 !important;
  }

  .multiselect-item-checkbox input + div:after {
    border-width: 0 0 2px 2px !important;
  }

  .selected-item {
    margin: 0 4px 0 0 !important;
    padding: 0 !important;
    max-width: 100% !important;
  }
}
