@use "variables.scss";

.order__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid variables.$GreyMedium;

  @media (max-width: variables.$breakpoint-xs) {
    padding: 12px 16px;
  }
}
.order__title {
  margin: 0;
  display: flex;
  align-items: center;
}
.order__icon,
.order__icon svg {
  width: 20px;
  height: 20px;
}
.order__icon {
  margin-right: 8px;
}
.order__flex {
  display: flex;

  @media (max-width: variables.$breakpoint-m-2) {
    flex-wrap: wrap;
  }
}
.order__flex__col {
  padding: 24px;

  @media (max-width: variables.$breakpoint-xs) {
    padding: 16px;
  }

  &:nth-child(1) {
    width: 180px;

    @media (max-width: variables.$breakpoint-xs) {
      width: 100%;
      border-right: 0 !important;
      border-bottom: 1px solid variables.$GreyMedium;
    }
  }
  &:nth-child(2) {
    width: 392px;

    @media (max-width: 1000px) {
      flex-grow: 1;
      border-right: 0 !important;
    }
  }
  &:nth-child(3) {
    width: 263px;

    @media (max-width: variables.$breakpoint-m-2) {
      flex-grow: 1;
    }
    @media (max-width: 1000px) {
      width: 100%;
      border-right: 0 !important;
      border-top: 1px solid variables.$GreyMedium;
    }
  }
  &:nth-child(4) {
    width: 192px;
  }
  &:last-child {
    flex-grow: 1;

    @media (max-width: variables.$breakpoint-m-2) {
      width: 100%;
      border-top: 1px solid variables.$GreyMedium;
    }
  }
  &:not(:last-child) {
    border-right: 1px solid variables.$GreyMedium;
  }
}
.order__top .button__flex {
  @media (max-width: variables.$breakpoint-m-1) {
    display: none;
  }
}
.order__heading {
  margin-bottom: 8px;
  white-space: nowrap;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.order__status {
  font-size: 20px;
  line-height: 18px;
}
.order__item {
  &:not(:last-child) {
    margin-bottom: 24px;
  }
}
.order__flex__col--no-padding {
  padding: 0;
}
.order__flex__split {
  padding: 24px;

  @media (max-width: variables.$breakpoint-xs) {
    padding: 16px;
  }
  &:not(:last-child) {
    border-bottom: 1px solid variables.$GreyMedium;
  }
}

.order__list-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  .tag {
    margin-left: 8px;
  }
}
