@use "variables.scss";

.table-properties {
  border-collapse: collapse;
  width: 100%;
  margin: 0 0 16px 0;

  &__flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__flex--blue {
    color: variables.$SurfBlue100;
    font-weight: 600;
  }

  &__flex--disabled {
    opacity: 0.25;
  }

  .alias__form {
    display: flex;
    align-items: center;
  }

  .alias__form-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    cursor: pointer;
    transition: all 0.2s ease;

    &--save {
      color: variables.$SurfBlue100;
      margin-left: 4px;
    }

    &--cancel {
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }

    svg-icon {
      display: flex;
    }
  }

  .alias {
    display: inline-block;
    position: relative;
    padding-right: 28px;
  }
  .alias__icon {
    position: absolute;
    top: -1px;
    right: 0;
    color: variables.$BlackLight;
    cursor: pointer;

    &:hover {
      color: variables.$Black;
    }
  }

  &--no-margin {
    margin: 0;
  }

  td {
    border-top: 1px solid variables.$GreyMedium;
    padding: 12px 0;
    vertical-align: top;
    line-height: 1.8;
    padding-left: 4px;

    &:first-child {
      font-weight: 700;
      color: variables.$Black;
      width: 35%;
      padding-left: 0px;
    }

    .link {
      color: variables.$SurfBlue100;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media (max-width: variables.$breakpoint-s-2) {
    tr,
    td {
      display: block;
      width: 100% !important;
      border: 0;
    }

    td:first-child {
      padding-bottom: 0;
      border: 0;
      border-top: 1px solid variables.$GreyMedium;
    }
  }
}

.table__wrapper {
  @media (max-width: variables.$breakpoint-m-2) {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }
}
.table {
  border-collapse: collapse;
  min-width: 100%;

  th {
    background-color: variables.$GreyLight;
    color: variables.$Black;
    font-family: variables.$font-heading;
    text-align: left;
    padding: 10px 16px;

    &:first-child {
      border-radius: variables.$border-radius 0 0 0;
    }
    &:last-child {
      border-radius: 0 variables.$border-radius 0 0;
    }
  }
  td {
    white-space: nowrap;
    padding: 12px 16px;
    border-bottom: 1px solid variables.$GreyMedium;
  }
}
.table__arrow,
.table__arrow svg {
  width: 24px;
  height: 24px;
}
.table__arrow {
  color: variables.$Black;
}

.clickable {
  cursor: pointer;

  &:hover {
    color: variables.$SurfBlue100;

    .table__arrow {
      color: variables.$SurfBlue100;
    }
  }
}

.table-settings {
  opacity: 0;
  transition: all 0.3s ease;
  cursor: pointer;

  svg {
    color: variables.$Black;
  }
}

tr:hover .table-settings {
  opacity: 1;
}

.table-overflow-menu {
  position: relative;

  .overflow-menu__items {
    top: 100%;
  }

  &:hover {
    .overflow-menu__items {
      opacity: 1;
      visibility: visible;
    }
  }
}
