@use "variables.scss";

.route {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
  position: relative;
}

.route__framework {
  width: 78px;
  height: 26px;
  position: relative;
}
.route__item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 5px solid variables.$GreyMedium;
    bottom: 1px;
    transform: translateY(50%);
    right: -5px;
  }
}
.route__item--flip {
  transform: scaleX(-1);
}
.route__line {
  width: 26px;
  height: 1px;
  background-color: variables.$GreyMedium;
  position: absolute;
  overflow: hidden;

  &:nth-child(1) {
    top: 0;
    left: 0;
  }
  &:nth-child(2) {
    left: 26px;
    top: 0;
    transform: skewY(44deg);
    transform-origin: left top;
  }
  &:nth-child(3) {
    bottom: 0;
    right: 0;
  }

  &:nth-child(1) .route__motion {
    &:nth-child(1) {
      animation-delay: 0s;
    }
    &:nth-child(2) {
      animation-delay: 1s;
    }
    &:nth-child(3) {
      animation-delay: 2s;
    }
  }

  &:nth-child(2) .route__motion {
    &:nth-child(1) {
      animation-delay: 1s;
    }
    &:nth-child(2) {
      animation-delay: 2s;
    }
    &:nth-child(3) {
      animation-delay: 3s;
    }
  }

  &:nth-child(3) .route__motion {
    &:nth-child(1) {
      animation-delay: 2s;
    }
    &:nth-child(2) {
      animation-delay: 3s;
    }
    &:nth-child(3) {
      animation-delay: 4s;
    }
  }
}
.route__item--flip .route__line {
  &:nth-child(1) .route__motion {
    &:nth-child(1) {
      animation-delay: 0.55s;
    }
    &:nth-child(2) {
      animation-delay: 1.55s;
    }
    &:nth-child(3) {
      animation-delay: 2.55s;
    }
  }

  &:nth-child(2) .route__motion {
    &:nth-child(1) {
      animation-delay: 1.55s;
    }
    &:nth-child(2) {
      animation-delay: 2.55s;
    }
    &:nth-child(3) {
      animation-delay: 3.55s;
    }
  }

  &:nth-child(3) .route__motion {
    &:nth-child(1) {
      animation-delay: 2.55s;
    }
    &:nth-child(2) {
      animation-delay: 3.55s;
    }
    &:nth-child(3) {
      animation-delay: 4.55s;
    }
  }
}
.route__motion {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 8px;
  transform: translateX(-8px);
  animation: 2.5s routeMotion infinite linear;

  &--green {
    background-color: variables.$SurfGreen75;
  }
  &--blue {
    background-color: variables.$SurfBlue75;
  }
  &--orange {
    background-color: variables.$SurfOrange75;
  }
}
.route__col {
  text-align: center;
  padding: 0 16px;
}
.route__title {
  font-family: variables.$font-heading;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 16px;
  color: variables.$Black;
}
@keyframes routeMotion {
  0% {
    transform: translateX(-8px);
  }
  50% {
    transform: translateX(26px);
  }
  100% {
    transform: translateX(26px);
  }
}
