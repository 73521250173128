@use "variables.scss";

.filter {
  padding-right: 72px;

  .section {
    margin-bottom: 48px;
  }

  @media (max-width: variables.$breakpoint-m-2) {
    padding-right: 32px;
  }

  @media (max-width: variables.$breakpoint-s-2) {
    display: none;
  }

  section > h3 {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
  }
}
