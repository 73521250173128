@use "variables.scss";

.input,
.textarea,
.select-wrapper select {
  display: block;
  width: 100%;
  height: 48px;
  appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  border: 0;
  border-radius: variables.$border-radius;
  background-color: white;
  border: 1px solid variables.$GreyMedium;
  padding: 0 16px;
  font-family: variables.$font-normal;
  font-size: 14px;
  color: variables.$Black;
  transition: variables.$transition-fast;
  -webkit-font-smoothing: antialiased;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.06);

  &--sm {
    height: 40px;
  }

  &::placeholder {
    color: variables.$BlackMedium;
  }

  &::placeholder {
    color: variables.$BlackLight;
  }

  &--shadow {
    box-shadow: 0 1px 2px 0 rgba(black, 0.06);
  }

  &:disabled {
    background-color: variables.$WhiteCream;
    cursor: not-allowed;

    color: variables.$BlackMedium;
  }

  &:not(:disabled):hover {
    border-color: variables.$SurfBlue50;
  }
  &:focus {
    border-color: variables.$SurfBlue100;
    outline: 0;
  }

  &__addon {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    line-height: 40px;
    padding-right: 16px;
    color: variables.$BlackLight;
    font-size: 16px;
    pointer-events: none;
  }
}
.select-wrapper {
  position: relative;

  select {
    cursor: pointer;
    height: 48px;
    font-size: 16px;
    box-shadow: 0 1px 2px 0 rgba(black, 0.06);
  }

  &--sm select {
    height: 40px;
    font-size: 14px;
  }

  svg-icon {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
}
.input--witherror {
  border: 1px solid #f00;
}

.textarea {
  height: auto;
  min-height: 120px;
  padding: 16px;
  resize: none;
}
.input__wrapper,
.textarea__wrapper {
  position: relative;
  margin-bottom: 16px;

  .error-hint {
    color: variables.$StatusRed;
    font-size: 10px;
    margin-top: 4px;
  }
}
.input__wrapper--flex {
  display: flex;

  .input {
    border-radius: variables.$border-radius 0 0 variables.$border-radius;
    padding-right: 4px;
  }
}

.input__error {
  margin-top: 8px;
  color: variables.$StatusRed;
}

.input--search {
  height: 48px;
  font-size: 16px;
  background-image: url("/assets/images/icons/search.svg");
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: right 16px center;
  padding-right: 40px;

  &::placeholder {
    color: variables.$BlackMedium;
  }
}
.input__switch {
  display: block;
  padding: 0 16px;
  flex-shrink: 0;
  font-family: variables.$font-heading;
  background-color: variables.$SurfBlue100;
  color: white;
  height: 40px;
  line-height: 40px;
  border-radius: 0 variables.$border-radius variables.$border-radius 0;
  cursor: pointer;
  min-width: 64px;
  text-align: center;

  &:hover {
    background-color: variables.$SurfBlue75;
  }

  &:before {
    content: attr(data-standard);
  }

  &.active:before {
    content: attr(data-clicked);
  }
}
.form-label {
  display: block;
  color: variables.$Black;
  font-family: variables.$font-heading;
  margin: 0 0 8px 0;
  font-weight: 600;

  span {
    font-family: variables.$font-normal;
    color: variables.$BlackMedium;
    font-size: 11px;
  }
}
.form-explain {
  position: relative;
  top: -4px;
  font-size: 11px;
  margin-bottom: 8px;
}
.live-location {
  display: flex;
}
.live-location__caption {
  padding-left: 8px;
  font-family: variables.$font-heading;
  color: variables.$SurfBlue100;
}
.live-location__pulse {
  width: 16px;
  height: 16px;
  position: relative;

  &:before {
    content: "";
    width: 4px;
    height: 4px;
    border-radius: 99em;
    background-color: variables.$SurfBlue100;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  span {
    position: absolute;
    border: 1px solid variables.$SurfBlue100;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 99em;
    opacity: 0.5;
    animation: 1.5s pulse infinite;

    &:nth-child(1) {
      width: 16px;
      height: 16px;
      animation-delay: 1s;
    }
    &:nth-child(2) {
      width: 12px;
      height: 12px;
      animation-delay: 0.5s;
    }
    &:nth-child(3) {
      width: 8px;
      height: 8px;
    }
  }
}
@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.6;
  }
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: variables.$Grey;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: variables.$Grey;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: variables.$Grey;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: variables.$Grey;
}

.form-info {
  display: flex;
}
.form-info__icon {
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  border-radius: 99em;
  border: 2px solid variables.$GreyMedium;
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
}

.toggle {
  position: relative;

  label {
    padding-left: 0 !important;
    display: inline-flex !important;
    align-items: center;
    gap: 8px;
  }
  label:before {
    opacity: 0;
    position: absolute;
  }

  input:disabled + label {
    opacity: 0.5;
  }
}

.custom-checkbox {
  input[type="checkbox"] + label:before {
    content: "";
    position: absolute;
    top: 1px;
    left: 0;
    width: 20px;
    height: 20px;
    background-color: white;
    border: 1px solid variables.$Grey;
    border-radius: 4px;
    transition: 0.235s ease;
    background: none;
  }

  input[type="checkbox"] + label {
    line-height: 20px;
    padding-left: 28px;
    color: variables.$Black;

    &:hover {
      &:before {
        border-color: variables.$SurfBlue100;
      }
    }
  }

  input[type="checkbox"]:checked + label:after {
    content: "";
    position: absolute;
    left: 6px;
    top: 7px;
    width: 9px;
    height: 5px;
    border-left: 2px solid white;
    border-bottom: 2px solid white;
    transform: rotate(-45deg);
  }
}
