@use "_type.scss";
@use "variables.scss";

.activity {
  padding: 48px 16px 32px 16px;
  max-height: 100vh;
  overflow-y: auto;

  @media (min-width: variables.$breakpoint-xs) {
    padding: 48px;
  }
}
.activity__flex {
  display: flex;
  align-items: center;

  + .activity__description {
    padding-top: 32px;
  }
}
.activity__icon {
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: variables.$border-radius;
  flex-shrink: 0;
  color: variables.$SurfBlue100;
  background-color: variables.$SurfBlue25;
  margin-right: 24px;

  svg-icon svg {
    width: 32px;
    height: 32px;
  }
}
.activity__title {
  margin: 0;
  font-weight: bold;
  line-height: 1.3;
}
.activity__subtitle {
  @include type.type-400;
}
.activity__description {
  p {
    @include type.type-400;
  }
}
.activity__services {
  margin: 0 -32px;

  @media (min-width: variables.$breakpoint-xs) {
    margin: 40px -48px 0;
  }
}
.activity__services-header {
  margin-top: 32px;
  display: flex;
  background-color: variables.$WhiteCream;
  text-transform: uppercase;
  padding: 12px 32px;
  font-weight: bold;

  @media (min-width: variables.$breakpoint-xs) {
    padding: 12px 48px;
  }
}
.activity__services-row {
  position: relative;
  cursor: pointer;
  display: flex;
  padding: 16px 32px;
  font-family: variables.$font-heading;
  color: variables.$Black;
  border-bottom: 1px solid variables.$GreyMedium;

  svg-icon {
    position: absolute;
    right: 24px;
    top: 16px;
    opacity: 0;
    transition: all 0.3s ease;
  }

  &:hover svg-icon {
    opacity: 1;
  }

  span {
    font-family: variables.$font-normal;
    color: variables.$BlackMedium;
  }

  @media (min-width: variables.$breakpoint-xs) {
    padding: 16px 48px;
  }
}
.activity__services-col {
  padding-right: 8px;
  div {
    margin-bottom: 4px;

    &:first-child {
      font-weight: 600;
    }
  }
  &:first-child {
    width: 60%;
  }
  &:last-child {
    width: 40%;
  }
}
