@use "variables.scss";

.tags {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
}
.tag {
  padding: 0 8px;
  background-color: variables.$GreyMedium;
  font-family: variables.$font-heading;
  color: variables.$Black;
  border-radius: variables.$border-radius-s;
  height: 24px;
  line-height: 24px;
  margin-right: 8px;
  font-weight: 600;

  @media (max-width: variables.$breakpoint-xs) {
    margin-bottom: 8px;
  }

  &--firewall {
    background-color: variables.$SurfRed25;
    color: variables.$SurfRed75;
    padding: 0;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg-icon {
      display: flex;
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }

  &--green {
    background-color: variables.$StatusGreen;
    color: white;
  }
  &--yellow {
    background-color: variables.$StatusYellow;
    color: white;
  }
  &--orange {
    background-color: variables.$StatusOrange;
    color: white;
  }
  &--red {
    background-color: variables.$StatusRed;
    color: white;
  }
  &--blue {
    background-color: variables.$SurfBlue25;
  }
}
