@use "variables.scss";

.tags-input__wrapper {
  display: block;
  width: 100%;
  appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  border: 0;
  border-radius: variables.$border-radius;
  background-color: white;
  border: 1px solid variables.$GreyMedium !important;
  font-family:
    "Roboto",
    -apple-system,
    BlinkMacSystemFont,
    segoe ui,
    roboto,
    oxygen,
    ubuntu,
    cantarell,
    fira sans,
    droid sans,
    helvetica neue,
    sans-serif;
  font-size: 13px;
  transition: variables.$transition-fast;
  -webkit-font-smoothing: antialiased;

  .tags-input__input-field {
    padding: 12px 16px 12px 8px;
    font-size: 13px;

    &:first-child {
      padding-left: 16px;
    }
  }
}
.tags-input__tag {
  background-color: variables.$Black;
  color: white;
  border-radius: variables.$border-radius-s;
  margin-left: 8px;
  margin-right: 0 !important;
  margin-bottom: 0 !important;
  padding: 0 8px;
  padding-right: 8px !important;
  height: 24px;
  line-height: 24px;
  font-family: variables.$font-heading;

  &:nth-child {
    margin-left: 16px;
  }
}
.tags-input__tag-remove-btn {
  display: none !important;
}
.tags-input {
  flex-wrap: nowrap !important;
}
