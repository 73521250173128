@use "variables.scss";

.port__wrapper {
  display: flex;
  margin: 0 -8px;
  padding-left: 28px;

  @media (max-width: variables.$breakpoint-xs) {
    padding-left: 0;
  }

  &:not(:first-child) {
    margin-top: 16px;
  }
}
.port__title {
  margin-bottom: 8px;

  &:only-child {
    margin-bottom: 0;
  }
}
.port__description {
  font-size: 11px;
}
.port__col {
  display: flex;
  align-items: center;
  padding: 0 8px;

  &:nth-child(1) {
    width: 40%;
  }
  &:nth-child(2) {
    width: 60%;
  }
}
.port__col:last-child:before {
  content: "";
  width: 24px;
  height: 24px;
  background-size: 100%;
  display: inline-block;
  vertical-align: middle;
  background-image: url("/assets/images/icons/arrow-long-black.svg");
  margin-right: 16px;
  flex-shrink: 0;
}
