@use "variables.scss";

.well {
  display: block;
  border-radius: variables.$border-radius;
  background-color: variables.$SurfBlue25;
  color: variables.$SurfBlue100;
  font-family: variables.$font-heading;
  font-size: 16px;
  padding: 12px 56px 12px 12px;
  line-height: 24px;
  margin-bottom: 16px;
  position: relative;
}

.well--red {
  background-color: variables.$SurfRed25;
  color: variables.$SurfRed100;
}
.well--orange {
  background-color: variables.$SurfOrange25;
  color: variables.$SurfOrange100;
}
.well--green {
  background-color: variables.$SurfGreen25;
  color: variables.$SurfGreen100;
}

.well__close {
  position: absolute;
  right: 0;
  top: 0;
  width: 48px;
  height: 48px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
