@use "variables.scss";

.webarchive-calendar {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 32px 48px 32px;
  grid-gap: 40px;

  @media (max-width: variables.$breakpoint-xs) {
    grid-template-columns: 1fr;
  }

  .flatpickr-input,
  .flatpickr-prev-month,
  .flatpickr-next-month,
  .flatpickr-current-month .numInputWrapper,
  .flatpickr-weekdays {
    display: none;
  }

  .flatpickr-calendar {
    width: 100%;
    border: 0;
    box-shadow: none;
  }

  .flatpickr-rContainer,
  .flatpickr-days,
  .dayContainer {
    width: 100%;
    min-width: 100%;
  }

  .flatpickr-day {
    position: relative;
    font-size: 11px;
    color: variables.$Black;
    height: 32px;
    line-height: 32px;
    border: 0 !important;

    &:hover {
      background-color: variables.$WhiteCream;
    }

    &.today {
      background-color: variables.$SurfBlue100;
      color: white;

      &:hover {
        background-color: variables.$SurfBlue100;
        color: white;
      }

      &.prevMonthDay,
      &.nextMonthDay {
        background-color: transparent;
        color: variables.$BlackLight;
      }
    }

    &.event:before,
    &.event:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 auto;
      width: 6px;
      height: 6px;
      border-radius: 99em;
    }

    &.event.event-malfunction:before {
      background-color: variables.$StatusRed;
    }

    &.event.event-maintenance:after {
      background-color: variables.$SurfBlue100;
    }

    &.event.event-malfunction.event-maintenance {
      &:before {
        transform: translateX(-3px);
      }
      &:after {
        transform: translateX(3px);
      }
    }
  }

  .flatpickr-day.selected {
    background-color: transparent;
    border-color: transparent;

    &:hover {
      color: variables.$Black;
    }
  }

  .flatpickr-month {
    height: 24px;
    pointer-events: none;
  }

  .flatpickr-current-month {
    font-size: 14px;
    height: 24px;
    color: variables.$Black;
  }

  .prevMonthDay,
  .nextMonthDay {
    color: variables.$BlackLight;
    pointer-events: none;
  }
}
