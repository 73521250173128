@use "variables.scss";

.inline-card {
  border-radius: variables.$border-radius;
  border: 1px solid variables.$GreyMedium;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.inline-card__item {
  padding: 16px;

  &--flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  + .inline-card__item {
    border-top: 1px solid variables.$GreyMedium;
  }
}
.inline-card__col--flex {
  display: flex;
}
.inline-card__title {
  margin: 0;
}
.inline-card__select-wrapper {
  position: relative;

  + .inline-card__select-wrapper {
    margin-left: 8px;
  }
}
.inline-card__select {
  font-family: variables.$font-heading;
  appearance: none;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding-right: 16px;
  height: 24px;
  line-height: 24px;
  font-size: 11px;
  direction: rtl;
  color: variables.$Black;
}

.inline-card__select-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  pointer-events: none;
  width: 16px;
  height: 16px;
}

.inline-card__status {
  display: flex;
}
.inline-card__status-icon {
  width: 24px;
  height: 24px;
  background-color: variables.$Grey;
  border-radius: 99em;
  flex-shrink: 0;
  margin-right: 8px;

  &--ok {
    background-color: variables.$StatusGreen;
  }
  &--unknown {
    background-color: variables.$StatusYellow;
  }
  &--warning {
    background-color: variables.$StatusOrange;
  }
}
.inline-card__status-title {
  margin: 0;
}
.inline-card__heading {
  margin-bottom: 8px;
}
.timeline__item {
  position: relative;
  padding: 2px 0 24px 16px;

  &:last-child {
    &:before {
      height: auto;
      bottom: 0;
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: 7px;
    left: 3px;
    width: 1px;
    height: 100%;
    background-color: variables.$GreyMedium;
  }
  &:after {
    content: "";
    position: absolute;
    width: 7px;
    height: 7px;
    top: 8px;
    left: 0;
    border-radius: 99em;
    background-color: variables.$GreyMedium;
  }
}
