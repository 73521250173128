@use "variables.scss";

.subscription-detail-container {
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  &__header {
    padding: 24px;

    .tag {
      margin-right: 0 !important;
      margin-left: 8px !important;
    }

    .port__items-container {
      margin-top: 8px !important;
    }
  }

  &__header-flex {
    display: flex;
    justify-content: space-between;
  }

  &__part {
    width: 50%;

    &--right {
      border-left: 1px solid variables.$GreyMedium;
      padding: 48px;

      @media (max-width: variables.$breakpoint-s-2) {
        padding: 24px;
        border-left: 0;
        border-top: 1px solid variables.$GreyMedium;
      }
    }

    @media (max-width: variables.$breakpoint-s-2) {
      width: 100%;
    }
  }

  &__list {
    max-height: 314px;
    overflow-y: auto;
    border-top: 1px solid variables.$GreyLight;

    @media (max-width: variables.$breakpoint-xs) {
      max-height: none;
    }
  }

  &__animate {
    animation: 0.5s fadeIn forwards;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid variables.$GreyLight;
    padding: 12px 24px;
    cursor: pointer;
    font-family: variables.$font-heading;
    color: variables.$Black;
    transition: color 0.2s ease;

    &.active {
      background-color: variables.$WhiteCream;
      .subscription-detail-container__item-title {
        font-weight: bold;
      }

      .subscription-detail-container__item-right svg-icon {
        opacity: 1;
      }
    }

    &:hover {
      color: variables.$SurfBlue100;

      .subscription-detail-container__item-right svg-icon {
        opacity: 1;
      }
    }
  }

  &__item-right {
    display: flex;
    align-items: center;
    white-space: nowrap;
    flex-shrink: 0;

    @media (max-width: variables.$breakpoint-s-2) {
      display: none;
    }

    svg-icon {
      display: flex;
      margin-left: 8px;
      opacity: 0;
      transition: 0.2s ease;
    }
  }

  &__item-left {
    display: flex;
    align-items: center;
    min-width: 0;
    padding-right: 16px;
  }

  &__icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: variables.$GreyMedium;
    width: 32px;
    height: 32px;
    border-radius: 2px;
    margin-right: 12px;
    flex-shrink: 0;

    svg-icon {
      display: flex;
    }

    &--lightpath,
    &--l2vpn {
      background: variables.$SurfGreen25;
      svg-icon {
        color: variables.$SurfGreen100;
      }
    }
    &--internet,
    &--ip {
      background: variables.$SurfBlue25;
      svg-icon {
        color: variables.$SurfBlue100;
      }
    }
    &--l3vpn {
      background: variables.$SurfOrange25;
      svg-icon {
        color: variables.$SurfOrange100;
      }
    }
    &--port {
      background: variables.$SurfPurple25;
      svg-icon {
        color: variables.$SurfPurple100;
      }
    }
  }

  &__icon-overlap-square {
    position: absolute;
    bottom: -2px;
    right: -2px;
    width: 12px;
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: variables.$border-radius;
    background-color: variables.$SurfRed25;
    box-shadow: 0 4px 6px -1px rgba(black, 0.1);

    svg-icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    svg {
      width: 12px !important;
      height: 12px !important;
      display: flex;
      color: variables.$SurfRed75 !important;
    }
  }

  &__item-title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 300;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
