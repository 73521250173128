@use "variables.scss";

.empty-state {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 48px;
  row-gap: 16px;
  text-align: center;

  &--icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    column-gap: 16px;
  }

  h3 {
    margin: 0;
    padding: 0;
  }

  a {
    font-family: variables.$font-heading;
    text-decoration: none;
    color: variables.$SurfBlue100;
    font-weight: 600;
    transition: variables.$transition-fast;

    &:hover {
      color: variables.$SurfBlue100;
    }
  }
}
