@use "variables.scss";

.onboarding__slide {
  width: 100%;
  left: 0 !important;
  opacity: 0;
  transition: opacity 0s ease-in-out;
  z-index: -1;
  display: flex;
  flex-direction: column;
  transform: none !important;

  &.is-selected {
    opacity: 1;
    z-index: 0;
    height: 100%;
  }
}

.onboarding__caption {
  padding: 24px 32px 32px 32px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 256px;

  @media (max-width: variables.$breakpoint-xs) {
    padding: 16px;
  }
}

.onboarding__text {
  opacity: 0;
  transform: translateX(24px);
  transition: 0.3s ease;
  flex-grow: 1;
}
.onboarding__aspect {
  background-color: variables.$WhiteCream;
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    display: block;
    padding-top: 63%;

    @media (max-width: variables.$breakpoint-xs) {
      padding-top: 50%;
    }
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 32px;
    background: linear-gradient(rgba(black, 0), rgba(black, 0.1));
  }
}
.onboarding__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  opacity: 0;
  transition: 0.3s ease 0.2s;
  transform: translateY(80px);

  + .onboarding__image {
    transition-delay: 0.3s;
  }

  &--from-left {
    transform: translateX(-80px);
    transition-delay: 0.4s;
  }
  &--from-right {
    transform: translateX(80px);
    transition-delay: 0.2s;

    + .onboarding__image {
      transition-delay: 0.4s;
    }
  }
}
.is-selected {
  .onboarding__image {
    opacity: 1;
    transform: translate(0, 0);
  }
  .onboarding__text {
    opacity: 1;
    transform: translate(0, 0);
  }
}
.onboarding__bottom {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .button + .button {
    margin-left: 8px;
  }
}
.onboarding__heading {
  margin: 0;
}

.onboarding__skip {
  font-size: 12px;
  margin-right: 16px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.onboarding {
  .flickity-slider {
    transform: none !important;
  }
}

.onboarding__dots {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;

  @media (max-width: variables.$breakpoint-xs) {
    margin-bottom: 16px;
  }
}
.onboarding__dot {
  width: 8px;
  height: 8px;
  border-radius: 99em;
  margin: 0 4px;
  background-color: variables.$GreyMedium;
  cursor: pointer;
  display: block;
  transition: 0.2s ease;

  &.active {
    background-color: variables.$SurfBlue100;
  }
}

.onboarding__checkbox {
  position: relative;
  cursor: pointer;

  &-wrapper {
    flex-grow: 1;
    display: flex;
  }

  input[type="checkbox"] {
    position: absolute;
    top: 0;
    left: 0;

    &:checked {
      + div .onboarding__checkbox-box {
        background-color: variables.$SurfBlue100;
      }
    }
  }

  > div {
    display: flex;
    align-items: center;
  }

  &-box {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: 1px solid variables.$GreyMedium;
    flex-shrink: 0;
    margin-right: 8px;
  }

  &-text {
    color: variables.$Black;
  }
}
