@use "variables.scss";

.clipboard__rule {
  position: relative;
  display: inline-block;

  &:hover {
    .clipboard__link {
      opacity: 1;

      &:active {
        opacity: 0.8;
        transition: 0s;
      }
    }
  }
}
.clipboard__link {
  opacity: 0;
  color: variables.$SurfBlue100;
  text-decoration: underline;
  cursor: pointer;
  transition: variables.$transition-fast;
  display: inline-block;
  margin-left: 2px;

  &:hover {
    text-decoration: none;
  }
}

.clipboard__icon {
  color: variables.$BlackLight;

  &:hover {
    color: variables.$SurfBlue100;
  }
}
