@use "variables.scss";

input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  height: 24px;
  width: 24px;

  + label {
    cursor: pointer;
    padding-left: 32px;
    line-height: 24px;
    position: relative;
    display: inline-block;
    margin-bottom: 0px;

    &:hover:before {
      border-color: variables.$SurfBlue50;
    }
  }

  + label:before {
    content: "";
    position: absolute;
    top: 1px;
    left: 0;
    width: 24px;
    height: 24px;
    background-color: white;
    border: 2px solid variables.$GreyLight;
    border-radius: variables.$border-radius;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 16px;
    background-image: url("/assets/images/icons/checkbox.svg");
    transition: variables.$transition-fast;
  }

  &:checked + label {
    &:before {
      border-color: variables.$SurfBlue100;
      background-color: variables.$SurfBlue100;
    }
  }
}
