@use "variables.scss";

.wrapper--mail .container:first-child {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
.mail markdown {
  display: block;

  p {
    display: block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.mail {
  display: flex;
  background-color: white;
  border-radius: variables.$border-radius-l;
  box-shadow: variables.$box-shadow;
  overflow: hidden;
  flex-grow: 1;
  margin-bottom: 8px;

  @media (max-width: variables.$breakpoint-xs) {
    flex-wrap: nowrap;
    overflow-x: scroll;
    height: 400px;
  }
}
.mail__scroll {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  @media (max-width: variables.$breakpoint-xs) {
    overflow-x: hidden;
  }
}
.mail__list {
  max-width: 40%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border-right: 4px solid variables.$GreyLight;
  position: relative;

  @media (max-width: variables.$breakpoint-xs) {
    flex-shrink: 0;
    max-width: 75%;
    width: 75%;
  }
}
.mail__content {
  flex-grow: 1;
  position: relative;

  @media (max-width: variables.$breakpoint-xs) {
    flex-shrink: 0;
    max-width: 75%;
    width: 75%;
    overflow: hidden;
  }
}

.mail__list__title {
  height: 48px;
  line-height: 48px;
  margin: 0;
  padding: 0 24px;
  border-bottom: 1px solid variables.$GreyLight;
  width: 100%;
}

.mail__item {
  padding: 24px 16px;
  display: flex;
  border-bottom: 1px solid variables.$GreyLight;
  cursor: pointer;
  transition: variables.$transition-fast;
  border-left: 4px solid white;

  &.active {
    border-left: 4px solid variables.$SurfBlue100;
    cursor: default;
  }

  &:not(.active):hover {
    border-left: 4px solid variables.$GreyLight;
  }

  .tags {
    margin-bottom: 0;
    padding-top: 12px;
  }
}

.mail__icon {
  width: 40px;
  height: 40px;
  border-radius: 99em;
  background-color: #eee;
  flex-shrink: 0;
  display: flex !important;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (max-width: variables.$breakpoint-xs) {
    width: 32px;
    height: 32px;
  }

  &--unread:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 8px;
    height: 8px;
    border-radius: 99em;
    background-color: variables.$SurfBlue100;
    transform: translateY(12px);
  }

  svg {
    width: 24px;
    height: 24px;
  }

  &--red {
    background-color: variables.$SurfRed25;
    color: variables.$SurfRed100;
  }
  &--orange {
    background-color: variables.$SurfOrange25;
    color: variables.$SurfOrange100;
  }
  &--blue {
    background-color: variables.$SurfBlue25;
    color: variables.$SurfBlue100;
  }
}
.mail__caption {
  padding-left: 16px;
  width: calc(100% - 40px);
}
.mail__rule {
  white-space: nowrap;
  max-width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.mail__title {
  margin: 0;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;

  @media (max-width: variables.$breakpoint-xs) {
    font-size: 13px;
    line-height: 14px;
  }
}
.mail__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
}
.mail__date {
  font-size: 11px;
  white-space: nowrap;
  padding-left: 8px;
}

.mail__content .mail__top {
  padding: 24px;
  border-bottom: 1px solid variables.$GreyMedium;

  .tag {
    margin-right: 0;
    margin-left: 8px;
  }
  .mail__date {
    padding-left: 0;
  }
}
.mail__message {
  padding: 24px;
  table {
    margin-bottom: 16px;
  }
  td {
    padding-right: 10px;
  }
  ul {
    list-style-type: disc;
  }
}
