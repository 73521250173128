@use "_type.scss";
@use "variables.scss";

.card {
  display: block;
  background-color: white;
  border-radius: variables.$border-radius;
  padding: 24px;
  margin-bottom: 24px;
  box-shadow: variables.$box-shadow;
  overflow: hidden;

  @media (max-width: variables.$breakpoint-xs) {
    padding: 16px;
    margin-bottom: 16px;
  }

  &--flex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
  }

  &--grayscale {
    background-color: transparent;
  }
}
.card--clickable {
  cursor: pointer;
}
.card--cols .row {
  margin: 0 -36px;
}
.card--cols .col-sm-6 {
  padding: 0 36px;
}
.card__top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.card__top-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  h3 {
    margin: 0;
  }
}
.card__top-actions {
  display: flex;
  gap: 8px;
}
.card__bottom {
  border-top: 1px solid variables.$GreyMedium;
  margin-top: 24px;
  padding-top: 24px;
  display: flex;
  justify-content: flex-end;

  @media (max-width: variables.$breakpoint-xs) {
    margin-top: 16px;
    padding-top: 16px;
  }
}
.card__link__arrow {
  width: 16px;
  height: 16px;
  margin-left: 8px;
  transition: variables.$transition-fast;
}
.card--no-padding {
  padding: 0 !important;
}

.card--loader {
  display: block;
  margin-top: 50px;
}

.card--empty {
  text-align: center;
}

.quick {
  width: 20px;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  cursor: pointer;

  span {
    width: 4px;
    height: 4px;
    border-radius: 99em;
    background-color: variables.$Black;
  }
}

.quick__wrapper {
  position: relative;
}
.dialog__body .quick__wrapper {
  @media (max-width: variables.$breakpoint-xs) {
    display: inline-block;
    float: right;
  }
}
.quick__options {
  position: absolute;
  background-color: white;
  padding: 1px;
  right: 0;
  top: 100%;
  transform: translateY(10px);
  z-index: 2;
  border: 1px solid variables.$GreyMedium;
  border-radius: variables.$border-radius 0 variables.$border-radius variables.$border-radius;
  box-shadow: 0 2px 5px 0 rgba(black, 0.05);
  visibility: hidden;
  opacity: 0;
  transition: variables.$transition-fast;

  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-bottom: 17px solid white;
    border-left: 16px solid transparent;
    bottom: 100%;
    right: 0;
  }

  &:before {
    right: -1px;
    border-bottom-color: variables.$GreyMedium;
    transform: scale(1.15);
    transform-origin: right bottom;
  }
}
.quick.clicked {
  span {
    background-color: variables.$SurfBlue100;
  }

  + .quick__options {
    visibility: visible;
    opacity: 1;
    transform: translateY(20px);
  }
}
.quick__option {
  height: 32px;
  line-height: 32px;
  padding: 0 16px;
  font-size: 11px;
  white-space: nowrap;
  font-family: variables.$font-heading;
  text-transform: uppercase;
  color: variables.$Black;
  cursor: pointer;
  transition: variables.$transition-fast;
  border-radius: variables.$border-radius-s;

  &:hover {
    background-color: variables.$SurfBlue25;
  }
}

.activity-card {
  width: 100%;
  padding: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  h2 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > div {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  ul {
    flex-grow: 1;
  }

  &__empty-state {
    min-height: 15rem;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &--text {
      color: variables.$BlackLight;
    }
    &--icon {
      margin-bottom: 1rem;
    }
  }
  &__unplanned {
    background: variables.$SurfRed100;
    svg-icon {
      width: 24px;
      height: 24px;
    }
    svg {
      color: variables.$SurfRed25;
      width: 24px;
      height: 24px;
    }
    h2 {
      color: white;
    }
  }
  &__header {
    @include variables.border-bottom();
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 20px;
    min-height: 48px;

    h2 {
      @include type.type-300();
      font-weight: bold;
      margin-bottom: 0;
      letter-spacing: 0;
    }

    svg-icon {
      display: flex;
      color: variables.$SurfBlue100;
      margin-right: 8px;
    }

    &.success {
      background-color: variables.$StatusGreen;

      svg-icon svg {
        color: variables.$StatusGreenLight !important;
      }
    }
  }
  &__item {
    padding: 16px;
    cursor: pointer;
    @include variables.border-bottom();
    transition: 0.2s background ease;
    &:last-child {
      border: 0;
    }
    &:hover,
    &:focus,
    &:active {
      background: variables.$WhiteCream;
    }
  }
  &__message {
    font-weight: bold;
    display: block;
    margin-bottom: 4px;
    color: variables.$Black;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__time {
    display: block;
  }
  &__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    @include variables.border-top();

    a {
      @include type.link();
      font-family: variables.$font-heading;
      font-weight: 600;
      padding: 1rem;
      width: 100%;
      text-align: center;
    }
  }
}
