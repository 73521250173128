.aspect-square {
  aspect-ratio: 1/1;
}

.mt-1 {
  margin-top: 4px;
}

.bg-black {
  background-color: #414042;
}
.bg-black-medium {
  background-color: #6d6e71;
}
.bg-black-light {
  background-color: #acb3b9;
}
.bg-grey {
  background-color: #d9dcde;
}
.bg-grey-medium {
  background-color: #e6e9eb;
}
.bg-grey-light {
  background-color: #f2f4f5;
}
.bg-white-cream {
  background-color: #f5f6fa;
}
.bg-white {
  background-color: white;
}

.bg-surf-blue-100 {
  background-color: #177abf;
}
.bg-surf-blue-75 {
  background-color: #519bcf;
}
.bg-surf-blue-50 {
  background-color: #8bbcdf;
}
.bg-surf-blue-25 {
  background-color: #d0e4f2;
}

.bg-surf-red-100 {
  background-color: #e7303a;
}
.bg-surf-red-75 {
  background-color: #ed636b;
}
.bg-surf-red-50 {
  background-color: #f2979c;
}
.bg-surf-red-25 {
  background-color: #fad5d7;
}

.bg-surf-orange-100 {
  background-color: #ee7628;
}
.bg-surf-orange-75 {
  background-color: #f2975d;
}
.bg-surf-orange-50 {
  background-color: #f6ba93;
}
.bg-surf-orange-25 {
  background-color: #fbe3d4;
}

.bg-surf-green-100 {
  background-color: #2ca055;
}
.bg-surf-green-75 {
  background-color: #60b77f;
}
.bg-surf-green-50 {
  background-color: #95cfaa;
}
.bg-surf-green-25 {
  background-color: #d4ecdd;
}

.bg-surf-purple-100 {
  background-color: #7b2882;
}
.bg-surf-purple-75 {
  background-color: #9c5da1;
}
.bg-surf-purple-50 {
  background-color: #bc93c0;
}
.bg-surf-purple-25 {
  background-color: #e4d4e6;
}

.bg-surf-lime-100 {
  background-color: #a5c251;
}
.bg-surf-lime-75 {
  background-color: #bbd17b;
}
.bg-surf-lime-50 {
  background-color: #d1e0a7;
}
.bg-surf-lime-25 {
  background-color: #edf2dc;
}

.rounded {
  border-radius: 4px;
}
