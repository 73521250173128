@font-face {
  font-family: "Source Sans Pro";
  src: url("/assets/fonts/SourceSansProRegular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("/assets/fonts/SourceSansProSemibold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Proxima";
  src: url("/assets/fonts/ProximaNovaSoftRegular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Proxima";
  src: url("/assets/fonts/ProximaNovaSoftMedium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Proxima";
  src: url("/assets/fonts/ProximaNovaSoftSemibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Proxima";
  src: url("/assets/fonts/ProximaNovaSoftBold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
