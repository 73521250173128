@use "variables.scss";

.blog {
  display: block;
  overflow: hidden;
  max-height: 248px;
  text-overflow: ellipsis;
}

.blog__meta {
  font-family: variables.$font-heading;
  display: flex;
  margin-bottom: 16px;
  font-size: 11px;
  color: variables.$Black;
}
.blog__meta__author {
  margin-right: 8px;
}
.blog__meta__date {
  &:before {
    content: "";
    position: relative;
    top: -1px;
    display: inline-block;
    vertical-align: middle;
    background-color: variables.$SurfBlue100;
    width: 4px;
    height: 4px;
    border-radius: 99em;
    margin-right: 8px;
  }
}
.blog__title {
  @media (max-width: variables.$breakpoint-xs) {
    font-size: 24px;
    line-height: 32px;
  }
}
